import React, { useRef, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { apiGateway } from "../../utils/config";
import { GET_SERIES_SIMILAR_TITLES } from "../../utils/constants";
import { handleModifyFirstLetterToCapital } from "../../utils/utils";
import {
  topicDetailsState,
  topicsMissingFields,
} from "../../utils/constants/Topics";
import CustomModal from "../Common/CustomModal";
import InputTitleWithRequired from "../Common/InputTitleWithRequired";
import CustomInput from "../Common/CustomInput";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const AddTopicsModal = ({
  openModal,
  showSlug,
  setMessage,
  handleClose,
  handleGetUpdatedTopics,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [seriesTitles, setSeriesTitles] = useState([]);
  const [topicDetails, setTopicDetails] = useState(topicDetailsState);
  const [missingFields, setMissingFields] = useState(topicsMissingFields);
  const axiosPrivate = useAxiosPrivate();
  const debounceTimeoutRef = useRef(null); // Ref to store the timeout
  const today = new Date().toISOString().split("T")[0];

  const handleGetSimilarTopics = async ({ value }) => {
    const url = `${apiGateway}${GET_SERIES_SIMILAR_TITLES}/?q=${value}`;
    try {
      const { data } = await axiosPrivate.get(url);
      if (data) {
        setSeriesTitles(data.series_titles);
      }
    } catch (error) {
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  const handleInputTitleChange = (e) => {
    const { value } = e.target;
    setTopicDetails({ ...topicDetails, title: value });
    setMissingFields({ ...missingFields, title: false });

    // Clear any previously set timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Setting a new timeout
    debounceTimeoutRef.current = setTimeout(() => {
      handleGetSimilarTopics({ value });
      setShowDropdown(true);
    }, 800);
  };

  const inputFields = [
    {
      id: 1,
      title: "Title",
      type: "text",
      placeholder: "Enter title of the topic",
      value: topicDetails?.title,
      isRequired: true,
      error: missingFields?.title,
      onChange: handleInputTitleChange,
    },
    {
      id: 2,
      title: "Video Publish Date",
      type: "date",
      placeholder: "",
      value: topicDetails?.date,
      isRequired: true,
      error: missingFields?.date,
      min: today,
      onChange: (e) => {
        const { value } = e.target;
        setTopicDetails({ ...topicDetails, date: value });
        setMissingFields({ ...missingFields, date: false });
      },
    },
    {
      id: 3,
      title: "Reference",
      type: "text",
      placeholder: "Any reference",
      value: topicDetails?.reference,
      isRequired: false,
      error: false,
      onChange: (e) => {
        const { value } = e.target;
        setTopicDetails({ ...topicDetails, reference: value });
      },
    },
  ];

  // Functions checks the missing fields
  const handleValidateFields = () => {
    const updatedMissingFields = {
      ...topicsMissingFields,
    };
    Object.keys(topicDetails).forEach((field) => {
      const skipIfFiled = field === "reference";
      if (skipIfFiled) return;
      if (!topicDetails[field]) {
        updatedMissingFields[field] = true;
      } else {
        updatedMissingFields[field] = false;
      }
    });
    setMissingFields(updatedMissingFields);
    const hasMissingFields = Object.values(updatedMissingFields).includes(true);
    return hasMissingFields;
  };

  const handleSaveApiCall = async () => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/cms/${showSlug}/series-title/create/`;
      const payload = {
        title: handleModifyFirstLetterToCapital({
          sentence: topicDetails?.title,
        }),
        published_on: topicDetails?.date,
      };
      if (topicDetails?.reference) payload.reference = topicDetails.reference;
      const response = await axiosPrivate.post(url, payload);
      const { data } = response;
      if (data) {
        setIsLoading(false);
        handleClose();
        handleGetUpdatedTopics();
        setMessage({ type: "success", error: "Topic Created Successfully" });
      }
    } catch (error) {
      setIsLoading(false);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  const handleSave = () => {
    if (handleValidateFields()) {
      setMessage({ type: "error", error: "Some fields are required" });
    } else {
      handleSaveApiCall();
    }
  };

  return (
    <CustomModal
      show={openModal}
      onHide={handleClose}
      isLoading={isLoading}
      title="Add Topics"
      className="lg:w-[35%]"
      handleConfirmationBtnText="Save"
      handleConfirmation={handleSave}
    >
      <div onClick={() => setShowDropdown(false)}>
        {inputFields?.map((item) => {
          return (
            <div key={item?.id} className="first:!mt-0 mt-4 relative">
              <InputTitleWithRequired
                title={item?.title}
                isRequired={item?.isRequired}
              />
              <div className="flex gap-x-2 mt-1">
                <CustomInput
                  type={item?.type}
                  placeholder={item?.placeholder}
                  value={item?.value}
                  error={item?.error}
                  min={item?.min}
                  className="!w-full rounded-md focus:outline-none placeholder:text-gray-500"
                  onChange={item?.onChange}
                />
              </div>

              {item?.id === 1 && seriesTitles?.length && showDropdown ? (
                <div>
                  <div className="px-2 py-1 flex justify-between bg-gray-100">
                    <div className="text-[13px] font-bold">Similar Topics</div>
                    <CloseButton
                      className="text-[20px]"
                      onClick={() => {
                        setShowDropdown(false);
                      }}
                    />
                  </div>

                  <ul className=" max-h-[150px] overflow-y-auto bg-white w-full z-[999] shadow-md">
                    {seriesTitles.map((title, index) => (
                      <li
                        key={index}
                        className="px-2 py-1 text-[14px] flex justify-between"
                      >
                        {title}{" "}
                        <span className="text-[10px] text-red-500 pr-1">
                          Already Exists!
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

export default AddTopicsModal;
