/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import "./create-series.styles.css";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createSeries } from "../../redux/series/series.action";
import { Chip, MenuItem, Paper, Select, styled } from "@mui/material";
import { apiGateway } from "../../utils/config";
import { selectConfigFilters } from "../../redux/config/config.selector";
import {
  faCamera,
  faImage,
  faTrashAlt,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {
  CATEGORY_V1,
  CREATE_SERIES,
  GET_SHOWS_LIST_V1,
  UNIT_V1,
} from "../../utils/constants";
import ImageEditor from "../ImageEditor/image-editor.component";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useZustandStore from "../../zustandStore/useZustandStore";
import axios from "axios";
import InputTitleWithRequired from "../Common/InputTitleWithRequired";
import CustomSelectLanguage from "../Common/CustomSelectLanguage";
import useUserRoles from "../../hooks/useUserRoles";

function CreateSeries({ handleFetchSeriesAfterCreated }) {
  const [creator, setCreator] = useState(null);
  const [titleIcon, setTitleIcon] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [description, setDescription] = useState("");
  const [isNews, setIsNews] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [addTitleImage, setAddTitleImage] = useState(false);
  const [listIsLoading, setListIsLoading] = useState(false);
  const [topicsLoading, setTopicsLoading] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [title, setTitle] = useState({ title: "", id: "" });
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const [showDetails, setShowDetails] = useState({
    id: "",
    slug: "",
    categoryId: "",
    categorySlug: "",
    creator: "",
  });
  const [showsList, setShowsList] = useState([]);
  const [seriesTitles, setSeriesTitles] = useState([]);
  const [primaryChips, setPrimaryChips] = useState([]);
  const [primaryChips1, setPrimaryChips1] = useState([]);
  const [creatorColor, setCreatorColor] = useState("#fff000");
  const [tagsMap] = useState(new Map());
  const [videoData, setVideoData] = useState({
    videoFile: null,
    videoDuration: null,
    videoThumbnail: null,
  });
  // common functions to add, update & delete the video in upload pop up
  const {
    addUpload,
    updateUploadProgress,
    removeUpload,
    setOpenProgressBarPopUp,
    setToastMessage,
    setOpenCreateSeriesModal,
  } = useZustandStore();
  const { internalTeamAccess } = useUserRoles();
  const dispatch = useDispatch();
  const titleIconRef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const globalLanguage = useSelector(
    (state) => state?.globalLanguage?.globalLanguage
  );
  const languageMapping = useSelector(selectConfigFilters)?.language_mapping;
  const languageSetter = {
    value: globalLanguage,
    title: languageMapping?.[globalLanguage],
  };
  const [language, setLanguage] = useState(languageSetter);

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  // API function to get list of shows
  const getShowsList = () => {
    setListIsLoading(true);
    const url = `${apiGateway}${GET_SHOWS_LIST_V1}/?page=1&page_size=1000000${
      internalTeamAccess ? `&language=${language?.value}` : ""
    }`;
    axiosPrivate
      .get(url)
      .then(({ data, status }) => {
        if (status === 200) {
          setShowsList(data?.show_list);
          setListIsLoading(false);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data?.message ?? "Something went wrong",
        });
        setListIsLoading(false);
      });
  };

  // Function to extract thumbnail from video's 1st frame
  const extractThumbnailFromVideo = async (videoFile) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = () => {
        videoElement.currentTime = 10;
      };
      videoElement.onseeked = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoElement, 0, 0, canvas?.width, canvas?.height);
        const thumbnailDataURL = canvas.toDataURL("image/jpeg");
        const thumbnailBlob = await fetch(thumbnailDataURL).then((res) =>
          res.blob()
        );
        resolve(thumbnailBlob);
      };
      videoElement.onerror = (error) => {
        reject(new Error("Error loading video: " + error));
      };
      videoElement.src = URL.createObjectURL(videoFile);
    });
  };

  // function on video change
  const handleVideoOnChange = async (e) => {
    const file = e.target?.files[0];
    if (file) {
      const videoURL = URL.createObjectURL(file);
      const videoElement = document.createElement("video");
      videoElement.src = videoURL;
      videoElement.onloadedmetadata = () => {
        const duration = Math.floor(videoElement?.duration);
        setVideoData((prevState) => ({
          ...prevState,
          videoFile: file,
          videoDuration: duration,
        }));
        URL.revokeObjectURL(videoURL); // Clean up the URL
      };
      // Extract thumbnail
      const thumbnailBlob = await extractThumbnailFromVideo(file);
      const thumbnailFile = new File([thumbnailBlob], "thumbnail.jpg", {
        type: "image/jpeg",
      });
      setVideoData((prevState) => ({
        ...prevState,
        videoThumbnail: thumbnailFile,
      }));
    }
  };

  // API function call when upload finished successfully
  const handleUploadFinishedAPI = async ({ slug }) => {
    try {
      const url = `${apiGateway}${UNIT_V1}${slug}/upload-finished/`;
      const response = await axiosPrivate.get(url);
      if (response?.data) {
        handleFetchSeriesAfterCreated();
        setToastMessage({ type: "success", message: "Uploaded Successfully" });
      }
    } catch (error) {
      setToastMessage({
        message: error?.response?.data?.error_message || error?.message,
        type: "failed",
      });
    }
  };

  // API function call when upload finished failed
  const handleUploadFailedAPI = async ({ slug }) => {
    try {
      const url = `${apiGateway}${UNIT_V1}${slug}/upload-failed/`;
      await axiosPrivate.get(url);
    } catch (error) {
      setToastMessage({
        message: error?.response?.data?.error_message || error?.message,
        type: "failed",
      });
    }
  };

  const handleUploadVideo = async ({ formData, uploadId }) => {
    try {
      const url = `${apiGateway}${CREATE_SERIES}`;
      // uploading the video and other data to BE
      const apiResponse = await axiosPrivate.post(url, formData);
      if (apiResponse?.status === 200) {
        setOpenCreateSeriesModal(false);
        const apiResponseData = apiResponse?.data;
        dispatch(createSeries(apiResponseData)); // Adding the created series
        // signedUrl key for final video upload
        const key =
          apiResponseData?.content_unit?.upload_presigned_url?.fields?.key;
        const slug = apiResponseData?.content_unit?.slug;
        // payload for video upload
        const videoFormData = new FormData();
        videoFormData.append("url", key);
        videoFormData.append("video", videoData?.videoFile);
        videoFormData.append("slug", slug);
        const cancelTokenSource = axios.CancelToken.source(); // cancel token
        // common function which allows async video uploads, adds the current upload in it
        addUpload({
          id: uploadId,
          title: title?.title,
          progress: 0,
          cancelToken: cancelTokenSource,
          cancelled: false,
        });
        try {
          // upload request
          const request = await axiosPrivate.post("/upload", videoFormData, {
            onUploadProgress: (progressEvent) => {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updateUploadProgress(uploadId, percentage); // updates the progress bar in pop up
              setOpenProgressBarPopUp(true); // Pop's up the video upload progress
            },
            cancelToken: cancelTokenSource.token,
          });
          const uploadResponse = await request; // uploaded response
          if (uploadResponse?.data) {
            handleUploadFinishedAPI({ slug }); // to check if video is uploaded
          }
        } catch (error) {
          handleUploadFailedAPI({ slug }); // video upload failed API call
          setToastMessage({ type: "failed", message: error?.message });
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setToastMessage({
          message: "Upload cancelled by user",
          type: "failed",
        });
      } else {
        setShowLoader(false);
        setToastMessage({
          message: error?.response?.data?.error_message || error?.message,
          type: "failed",
        });
      }
    } finally {
      removeUpload(uploadId);
    }
  };

  // function validates the missing fields
  const handleMissingFields = ({ title, videoData, primaryTagIds }) => {
    const missingFields = !title.title
      ? "Title"
      : videoData?.videoFile === null
      ? "Video"
      : !showDetails?.id
      ? "Show"
      : !primaryTagIds?.length
      ? "Tags"
      : null;
    return missingFields;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const primaryTagIds = primaryChips?.map((chip) =>
      tagsMap.get(chip)?.toString()
    );
    const tagIdsString = JSON.stringify(primaryTagIds);
    const isValid =
      !title?.title ||
      !primaryTagIds?.length ||
      videoData?.videoFile === null ||
      !showDetails?.id;
    if (isValid) {
      setErrorMsg({
        type: "failed",
        error: `${handleMissingFields({
          title,
          primaryTagIds,
          videoData,
        })} field is required`,
      });
    } else {
      // payload in form of FormData
      const formData = new FormData();
      formData.append("title", title?.title);
      formData.append("series_title_id", title?.id);
      formData.append("description", description);
      formData.append("category_id", showDetails?.categoryId);
      formData.append("add_title_in_image", addTitleImage);
      formData.append("is_news", isNews);
      formData.append("video_thumbnail_file", videoData?.videoThumbnail);
      formData.append("video_duration", videoData?.videoDuration);
      formData.append("video_extension", "mp4");
      formData.append("tag_ids", tagIdsString);
      if (internalTeamAccess) {
        formData.append("creator_id", showDetails?.creator);
        formData.append("lang", language?.value);
      }
      if (showDetails?.id) formData.append("show_id", showDetails?.id);
      if (thumbnail) formData.append("thumbnail_image", thumbnail);
      if (titleIcon) formData.append("title_icon", titleIcon);
      setShowLoader(true);
      try {
        const uploadId = title; // unique uploadId for every upload
        handleUploadVideo({ formData: formData, uploadId }); // Function breakdown for code readability
      } catch (error) {
        setErrorMsg(
          error.response?.data
            ? error.response.data.error_message
            : "Something went wrong"
        );
      } finally {
        setShowLoader(false);
      }
    }
  };

  // Fetches the list of tags
  const handleFetchSuggestions = async (data, cb, categorySlug) => {
    try {
      setTagsLoading(true);
      setPrimaryChips1([]);
      const url = `${apiGateway}${CATEGORY_V1}${categorySlug}/tags/`;
      const response = await axiosPrivate.get(url);
      const { data } = response;
      if (data) {
        setTagsLoading(false);
        const titles = data?.tags?.map((tag) => {
          tagsMap.set(tag?.title, tag?.id);
          return { title: tag?.title, id: tag?.id };
        });
        // Add the new tags to the state
        setPrimaryChips1(titles);
        // Callback with the titles
        cb && (await cb(titles.map((tag) => tag.title)));
      }
    } catch (error) {
      setTagsLoading(false);
      setErrorMsg({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // API function call to get the topics
  const handleGetTopics = async () => {
    setTopicsLoading(true);
    const url = `${apiGateway}/api/v1/cms/series-titles/?status=approved&show_slug=${showDetails?.slug}&page_size=2000`;
    try {
      const { data } = await axiosPrivate.get(url);
      if (data) {
        setTopicsLoading(false);
        setSeriesTitles(data?.series_title_list);
      }
    } catch (error) {
      setTopicsLoading(false);
      setErrorMsg({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // API whenever categorySlug changes
  useEffect(() => {
    if (showDetails?.categorySlug) {
      handleFetchSuggestions(null, null, showDetails?.categorySlug);
    }
  }, [showDetails?.categorySlug]);

  useEffect(() => {
    getShowsList();
  }, [language?.value]);

  // API whenever showSlug changes
  useEffect(() => {
    if (showDetails?.slug) {
      handleGetTopics();
    }
  }, [showDetails?.slug]);

  return (
    <div>
      <div>
        <AlertComponent
          message={errorMsg?.error}
          type={errorMsg?.type}
          setAlertNotification={() =>
            setErrorMsg({ error: "", type: "failed" })
          }
        />
        <Container className="create-series">
          <div>
            <Form onSubmit={handleSubmit}>
              {/* Language and quality */}
              {/* <div className="d-flex flex-1">
                <Form.Group
                    className="flex-1 w-50 mr-2"
                    controlId="exampleForm.ControlInput1"
                    // onClick={() => setShowSeriesTitle(true)}
                >
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                      as="select"
                      name="language"
                      onChange={(e) => {
                        setLang(e.target.value)
                      }}
                      value={lang}
                    >
                      {language?.map((it) => (
                        <option value={it.code} key={it.code}>{it?.language}</option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  className="flex-1 w-50"
                  controlId="exampleForm.ControlInput1"
                    // onClick={() => setShowSeriesTitle(true)}
                >
                  <Form.Label>Series Quality</Form.Label>
                  <Form.Control
                      as="select"
                      name="quality"
                      onChange={(e) => {
                        setSeriesQuality(e.target.value)
                      }}
                      value={seriesQual}
                    >
                      {SeriesQuality?.map((it) => (
                        <option value={it.value} key={it.value}>{it?.label}</option>
                      ))}
                  </Form.Control>
              </Form.Group>
              </div> */}
              {/* Language */}
              {internalTeamAccess && (
                <div className="mb-3">
                  <CustomSelectLanguage
                    language={language}
                    setLanguage={setLanguage}
                  />
                </div>
              )}

              {/* Show Name */}
              <div>
                <InputTitleWithRequired title="Show" />
                <Select
                  displayEmpty
                  className="!text-black !text-[14px] mt-1 w-full mb-3"
                  value={JSON.stringify(showDetails)}
                  onChange={(e) => {
                    const { value } = e.target;
                    const selectedItem = JSON.parse(value);
                    setShowDetails({
                      id: selectedItem?.id,
                      slug: selectedItem?.slug,
                      categoryId: selectedItem?.categoryId,
                      categorySlug: selectedItem?.categorySlug,
                      creator: selectedItem?.creator,
                    });
                    setPrimaryChips([]);
                    setPrimaryChips1([]);
                    setVideoData({
                      videoFile: null,
                      videoDuration: null,
                      videoThumbnail: null,
                    });
                  }}
                >
                  <MenuItem
                    value={JSON.stringify({
                      id: "",
                      slug: "",
                      categoryId: "",
                      categorySlug: "",
                      creator: "",
                    })}
                    className="!text-[14px] !font-semibold"
                    disabled={!showDetails.slug}
                    onClick={() => setTitle({ id: "", title: "" })}
                  >
                    {listIsLoading
                      ? "Loading...."
                      : showDetails?.slug
                      ? "Clear Selection"
                      : "Select Show"}
                  </MenuItem>
                  {showsList?.map((item) => {
                    return (
                      <MenuItem
                        key={item?.id}
                        value={JSON.stringify({
                          id: item?.id,
                          slug: item?.slug,
                          categoryId: item?.category?.id,
                          categorySlug: item?.category?.slug,
                          creator: item?.creator?.id,
                        })}
                        className="!text-[14px]"
                      >
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              {/* Video title */}
              <InputTitleWithRequired title="Video Title" />
              <Select
                displayEmpty
                disabled={!showDetails?.slug}
                className="!text-black !text-[14px] mt-1 w-full mb-3"
                value={JSON.stringify(title)}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value === "") {
                    setTitle({ title: "", id: "" });
                  } else {
                    const selectedItem = JSON.parse(value);
                    setTitle({
                      title: selectedItem.title,
                      id: selectedItem.id,
                    });
                  }
                }}
              >
                <MenuItem
                  value={JSON.stringify({ title: "", id: "" })}
                  className="!text-[14px] !font-semibold"
                  disabled={!title.title}
                >
                  <span>
                    {topicsLoading
                      ? "Loading....."
                      : title?.title
                      ? "Clear Selection"
                      : "Select Video Title"}
                  </span>
                </MenuItem>
                {seriesTitles?.map((item) => {
                  return (
                    <MenuItem
                      key={item?.id}
                      value={JSON.stringify({
                        title: item?.title,
                        id: item?.id,
                      })}
                      className="!text-[14px]"
                    >
                      {/* to copy the selected title */}
                      <span style={{ userSelect: "text" }}>{item?.title}</span>
                    </MenuItem>
                  );
                })}
                <MenuItem
                  value=""
                  className="!text-[14px] !font-medium !text-center !text-baseBlueColor"
                  onClick={() =>
                    history.push(`/shows-topics/${showDetails?.slug}`)
                  }
                >
                  Add Topics
                </MenuItem>
              </Select>

              {/* Video Upload */}
              <InputTitleWithRequired title="Video" />
              <div className="border-dashed border-2 border-indigo-300 text-center h-[85px] flex items-center justify-center relative mb-3 rounded-lg mt-1">
                <input
                  type="file"
                  accept=".mp4"
                  className="border h-[100%] absolute right-0 left-0 opacity-0"
                  onChange={handleVideoOnChange}
                />
                <div>
                  <FontAwesomeIcon icon={faVideo} className="text-gray-600" />
                  <h6 className="text-[12px] line-clamp-1">
                    {videoData?.videoFile?.name
                      ? videoData?.videoFile?.name
                      : "Upload Video"}
                  </h6>
                </div>
              </div>

              <div>
                {/* Tags */}
                <Form.Group
                  controlId="exampleForm.ControlTextarea1"
                  className="mt-3"
                >
                  <InputTitleWithRequired title="Tags" className="mb-1" />
                  <Select
                    displayEmpty
                    className="w-full !text-[14px]"
                    value=""
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val) setPrimaryChips((prev) => [...prev, val]);
                    }}
                    disabled={!showDetails?.id}
                  >
                    <MenuItem value="" disabled>
                      {tagsLoading ? "Loading....." : "Select Tags"}
                    </MenuItem>
                    {primaryChips1?.map(
                      (tag, i) =>
                        primaryChips.indexOf(tag?.title) < 0 && (
                          <MenuItem
                            value={tag?.title}
                            key={i}
                            className="!text-[14px]"
                          >
                            {tag?.title}
                          </MenuItem>
                        )
                    )}
                  </Select>

                  {primaryChips.length !== 0 && (
                    <Paper
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        listStyle: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      component="ul"
                    >
                      {primaryChips?.map((data, i) => {
                        let icon;
                        return (
                          <ListItem key={data}>
                            <Chip
                              icon={icon}
                              label={data}
                              className="px-2 text-[12px] !text-baseBlueColor !bg-baseFillColor"
                              onDelete={() =>
                                setPrimaryChips((prev) =>
                                  prev.filter((d) => d !== data)
                                )
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </Paper>
                  )}
                </Form.Group>

                {/* Thumbnail and title icon */}
                <Form.Group
                  className="cta mt-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <div>
                    <Button
                      variant="secondary"
                      active
                      onClick={(e) => {
                        e.preventDefault();
                        setShowImageEditor(true);
                      }}
                      className="!min-h-[85px] text-[12px] bg-transparent !text-black !border-dashed !border-2 !border-indigo-300 rounded-lg"
                    >
                      <FontAwesomeIcon icon={faCamera} /> <br />
                      Upload Thumbnail
                    </Button>
                    {thumbnail && (
                      <div>
                        <img
                          src={URL.createObjectURL(thumbnail)}
                          className="w-[100px] mx-auto mt-2"
                        />
                        <div className="flex justify-center mt-2">
                          <FontAwesomeIcon
                            className=""
                            icon={faTrashAlt}
                            onClick={() => setThumbnail(null)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="title-icon">
                    <Button
                      variant="success"
                      active
                      onClick={(e) => {
                        titleIconRef.current.click();
                      }}
                      className="!min-h-[85px] text-[12px] bg-transparent !text-black !border-dashed !border-2 !border-indigo-300 rounded-lg"
                    >
                      <FontAwesomeIcon icon={faImage} /> <br />
                      Select Title Icon
                    </Button>
                    <Form.Control
                      type="file"
                      name="titleIcon"
                      ref={titleIconRef}
                      onChange={(e) => setTitleIcon(e.target.files[0])}
                    />{" "}
                    {titleIcon && (
                      <div className="w-[80%] mx-auto mt-2">
                        <div className="bg-black">
                          <img src={URL.createObjectURL(titleIcon)} />
                        </div>
                        <div className="flex justify-center">
                          <FontAwesomeIcon
                            className="mt-2"
                            icon={faTrashAlt}
                            onClick={() => {
                              setTitleIcon(null);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Form.Group>

                {/* Description*/}
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter the description of video"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>

                {/* is News*/}
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Check
                    name="isNews"
                    onChange={(e) => {
                      setIsNews(e.target.checked);
                    }}
                    label="Is news?"
                  />
                </Form.Group>
              </div>
              <div>
                <Button
                  type="submit"
                  disabled={showLoader}
                  variant="outline-primary"
                  active
                  className="cta-update-series"
                >
                  {!showLoader ? "Create" : "Saving...."}
                </Button>
              </div>
            </Form>
          </div>
        </Container>

        <Modal className="image-editor-modal" show={showImageEditor}>
          <Modal.Header closeButton onHide={() => setShowImageEditor(false)}>
            <div className="modal-video-title">Select Thumbnail</div>
          </Modal.Header>
          <Modal.Body>
            <ImageEditor
              done={(file) => setThumbnail(file)}
              close={() => setShowImageEditor(false)}
              image={thumbnail && URL.createObjectURL(thumbnail)}
              creatorColor={creatorColor}
              setCreatorColor={(creatorColor) => setCreatorColor(creatorColor)}
              creatorName={creator?.name}
              seriesTitle={title}
              addTitleImage={addTitleImage}
              setAddTitleImage={setAddTitleImage}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default CreateSeries;
