import React from "react";
import { handleCreatorsTableHeading } from "../../utils/constants/Creators";
import useUserRoles from "../../hooks/useUserRoles";

const CreatorsListingSkeleton = () => {
  const { internalTeamAccess } = useUserRoles();
  const hasAccessToActions = internalTeamAccess;
  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-gray-700 border-b bg-gray-100">
          <tr>
            {handleCreatorsTableHeading({ hasAccessToActions }).map(
              (item) =>
                item?.display && (
                  <th
                    scope="col"
                    className="px-6 py-3 !text-[12px] font-medium bg-gray-100 whitespace-nowrap !z-[999]"
                    key={item?.id}
                  >
                    {item?.title}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {/* Skeleton rows */}
          {[...Array(10)].map((_, index) => (
            <tr className="border-b last:border-none animate-pulse" key={index}>
              <td className="px-6 py-4 whitespace-nowrap flex gap-x-3">
                <div className="w-[40px] h-[40px] rounded-full bg-gray-300"></div>
                <div>
                  <div className="h-4 bg-gray-300 rounded w-[150px]"></div>
                  <div className="h-4 bg-gray-300 rounded w-[150px] mt-1"></div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-300 rounded w-[180px]"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-300 rounded w-[100px]"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-300 rounded w-[100px]"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-300 rounded w-[90px]"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="h-4 bg-gray-300 rounded w-[50px]"></div>
              </td>
              {hasAccessToActions && (
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="h-4 bg-gray-300 rounded w-2/5"></div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CreatorsListingSkeleton;
