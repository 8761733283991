import React from "react";
import { Chip } from "@mui/material";
import { handleSeriesStatusColor } from "../../utils/constants/VideoListing";

const VideoStatusChip = ({ status }) => {
  return (
    <div>
      <Chip
        label={status?.toUpperCase()}
        className={` ${handleSeriesStatusColor({
          status: status,
        })} w-fit px-3 !text-[12px]`}
      />
    </div>
  );
};

export default VideoStatusChip;
