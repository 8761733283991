import React from "react";
import QueryValueChip from "./QueryValueChip";
import { handleRemoveParticularFilter } from "../../utils/utils";
import { useHistory } from "react-router-dom";

const FilterQueryChips = ({ allQueryParams, setSearchQuery }) => {
  const history = useHistory();
  return (
    <div>
      <div className="flex !gap-x-2">
        {Object.entries(allQueryParams)?.map(([key, values]) => (
          <div key={key}>
            <div className="flex gap-x-2">
              {values?.map((item, index) => (
                <QueryValueChip
                  key={index}
                  label={
                    item === "true"
                      ? "Reported"
                      : item === "false"
                      ? "Not reported"
                      : item
                  }
                  onDelete={() => {
                    if (setSearchQuery) setSearchQuery("");
                    handleRemoveParticularFilter({
                      filterName: key,
                      filterToRemove: item,
                      history,
                    });
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterQueryChips;
