import React, { useEffect, useState } from "react";
import { LinearProgress, Pagination, Tab, Tabs } from "@mui/material";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { apiGateway } from "../../utils/config";
import { errorState } from "../../utils/constants";
import {
  experimentsTabs,
  filtersDefaultState,
  handleConvertTabString,
} from "../../utils/constants/Experiments";
import {
  handleAllyProps,
  handleAppendFiltersToUrl,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
} from "../../utils/utils";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SeriesExperiments from "../Series-Experiments/series-experiments.component";
import CustomSearch from "../Common/CustomSearch";
import FilterButton from "../Common/FilterButton";
import ExperimentsTable from "./ExperimentsTable";
import ExperimentFilterDrawer from "./ExperimentFilterDrawer";
import "./experiments-list.styles.css";
import QueryValueChip from "../Common/QueryValueChip";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import useAllQueryParams from "../../hooks/useAllQueryParams";
import useUserRoles from "../../hooks/useUserRoles";

export default function ExperimentsList() {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentActiveTabValue, setCurrentActiveTabValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorState);
  const [showExperiments, setShowExperiments] = useState(false);
  const [seriesData, setSeriesData] = useState({});
  const [series, setSeries] = useState({ experiments: [], nPages: 0 });
  const [selectedFilters, setSelectedFilters] = useState(filtersDefaultState);
  const { internalTeamAccess, isViewer } = useUserRoles();
  const history = useHistory();
  const location = useLocation();
  const allQueryParams = useAllQueryParams({
    excludedParams: ["page", "state"],
  });
  const axiosPrivate = useAxiosPrivate();
  const searchParams = new URLSearchParams(location.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const searchParamSearchQuery = searchParams.get("query") || "";
  const searchParamStartDate = searchParams.get("startDate") || "";
  const searchParamEndDate = searchParams.get("endDate") || "";
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;
  const searchParamStatus = searchParams.get("status") || "";
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const hasAccessToActions = internalTeamAccess;
  const isDefaultRemakeSeriesTab = handleConvertTabString({
    tabValue: searchParamTabState,
  }).includes("default-remake-series");

  // function to get Experiments
  const handleGetExperiments = async ({ pageArg }) => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/cms/experiments/${handleConvertTabString(
        { tabValue: searchParamTabState }
      )}/?page=${pageArg}${
        searchParamStatus ? `&status=${searchParamStatus}` : ""
      }${searchParamStartDate ? `&start_on=${searchParamStartDate}` : ""}${
        searchParamEndDate ? `&end_on=${searchParamEndDate}` : ""
      }${searchParamSearchQuery ? `&q=${searchParamSearchQuery}` : ""}`;
      const response = await axiosPrivate.get(url);
      const { data } = response;
      const series = data?.series;
      const nPages = data?.n_pages;
      if (data) {
        setSeries({ experiments: series, nPages });
        setIsLoading(false);
      }
    } catch (error) {
      setSeries({ experiments: [], nPages: 0 });
      setIsLoading(false);
      setErrorMsg({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // On hit enter after search
  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    }
  };

  // when click on x cta in search
  const handleClearInput = () => {
    setSearchQuery("");
    handleDeleteQueryFromUrl({ history, queryNames: ["query"] });
  };

  // opens filter drawer
  const handleOpenFilterDrawer = () => {
    setShowFilter(true);
    setSelectedFilters({
      ...selectedFilters,
      status: searchParamStatus,
    });
  };

  // On Filter change
  const handleFilterChange = (event, filterType) => {
    const { value, checked, type } = event.target;

    setSelectedFilters((prevSelectedFilters) => {
      const updatedFilters = { ...prevSelectedFilters };

      if (filterType === "startDate" || filterType === "endDate") {
        updatedFilters[filterType] = [value];
      } else if (type === "checkbox") {
        if (!updatedFilters[filterType]) {
          updatedFilters[filterType] = [];
        }
        if (checked) {
          updatedFilters[filterType] = [...updatedFilters[filterType], value];
        } else {
          updatedFilters[filterType] = updatedFilters[filterType].filter(
            (filter) => filter !== value
          );
        }
      } else if (type === "radio") {
        updatedFilters[filterType] = checked ? [value] : [];
      }

      return updatedFilters;
    });
  };

  // On Apply Filters
  const handleApplyFilters = () => {
    handleAppendFiltersToUrl({
      history,
      selectedFilters,
    });
    setShowFilter(false);
  };

  // On Clear filters
  const handleClearFilters = () => {
    handleAppendFiltersToUrl({ history, selectedFilters: {} });
    setShowFilter(false);
  };

  // Tab Change
  const handleChangeTabs = (event, newValue) => {
    let state = experimentsTabs.filter((d) => d.index === newValue)?.[0]?.value;
    let x = handleConvertTabString({ tabValue: newValue });
    setCurrentActiveTabValue(x);
    handleAppendQueryToUrl({
      history,
      queryName: "state",
      queryValue: `${state}-${newValue}`,
    });
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
  };

  const handleDialogTitle = () => {
    if (currentActiveTabValue === "series-ctr") return "Series CTR";
    else if (currentActiveTabValue === "series-remake") return "Series Remake";
    return "Series Default";
  };

  const handleView = ({ data }) => {
    setSeriesData(data);
    setShowExperiments(true);
  };

  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  // effect triggers when ever any dependencies change
  useEffect(() => {
    handleGetExperiments({ pageArg: searchParamPage });
  }, [
    searchParamPage,
    searchParamTabState,
    searchParamSearchQuery,
    JSON.stringify(searchParamStatus),
    JSON.stringify(searchParamStartDate),
    JSON.stringify(searchParamEndDate),
  ]);

  return (
    <div>
      <div className="flex gap-x-3">
        <div className="w-[80%] lg:w-[50%]">
          <CustomSearch
            placeHolder="Search experiments"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleInputKey={handleInputKey}
            handleClearInput={handleClearInput}
          />
        </div>
        <FilterButton onClick={handleOpenFilterDrawer} />
      </div>
      <h6 className="!text-[20px] mt-3 font-medium">Experiments</h6>

      {/* Applied Filters Chips */}
      <div className="flex gap-x-2 mt-1">
        {Object.entries(allQueryParams)?.map(([key, values]) => (
          <div key={key}>
            <div className="flex flex-wrap gap-x-2">
              {values.map((item, index) => (
                <QueryValueChip
                  key={index}
                  label={item}
                  onDelete={() => {
                    handleDeleteQueryFromUrl({ history, queryNames: [key] });
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="mt-2">
        <Tabs
          value={searchParamTabState}
          onChange={handleChangeTabs}
          aria-label="basic tabs example"
          sx={{
            minHeight: 35,
            height: 35,
          }}
        >
          {experimentsTabs?.map(
            (data, i) =>
              data?.show === (internalTeamAccess || isViewer) && (
                <Tab
                  label={data?.id}
                  {...handleAllyProps(i)}
                  key={i}
                  sx={{
                    minHeight: 35,
                    height: 35,
                  }}
                />
              )
          )}
        </Tabs>
        {isLoading && <LinearProgress color="inherit" />}
        <div className="!relative h-[calc(100vh-225px)] !overflow-x-auto rounded-md !bg-white pb-4 border-t">
          {!isLoading && series?.experiments?.length == 0 ? (
            <div className="h-full flex justify-center items-center">
              <NoFeedbackSvj
                displayText="No experiments found or something went wrong"
                displayTextClass="mt-2 text-gray-400 text-[14px] text-center"
                className="w-[250px] mx-auto "
              />
            </div>
          ) : (
            <div>
              <ExperimentsTable
                data={series.experiments}
                isDefaultRemakeSeriesTab={isDefaultRemakeSeriesTab}
                hasAccessToActions={hasAccessToActions}
                handleView={handleView}
              />

              {series.nPages > 1 && (
                <div className="w-fit mx-auto mt-1">
                  <Pagination
                    count={series?.nPages}
                    page={searchParamPage || pageNumber}
                    onChange={(e, value) => {
                      setPageNumber(value);
                      handleAppendQueryToUrl({
                        history,
                        queryName: "page",
                        queryValue: value,
                      });
                    }}
                    shape="rounded"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <ExperimentFilterDrawer
        openFilter={showFilter}
        selectedFilters={selectedFilters}
        handleCloseFilter={() => setShowFilter(false)}
        handleFilterChange={handleFilterChange}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFilters}
      />

      {seriesData && (
        <Modal show={showExperiments} centered>
          <Modal.Header closeButton onHide={() => setShowExperiments(false)}>
            <div className="modal-experiments-title">{handleDialogTitle()}</div>
          </Modal.Header>
          <Modal.Body>
            <SeriesExperiments
              setErrorMsg={setErrorMsg}
              setSeries={setSeriesData}
              series={seriesData}
              alreadyExist={true}
              close={() => setShowExperiments(false)}
              currentActiveTabValue={searchParams.get("state")?.split("-")[0]}
            />
          </Modal.Body>
        </Modal>
      )}

      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() =>
          setErrorMsg({ error: "", type: errorMsg?.type })
        }
      />
    </div>
  );
}
