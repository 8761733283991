import React from "react";
import {
  handleDefaultProfile,
  handleFormatDateTimeAgo,
} from "../../utils/utils";
import CustomReadMore from "./CustomReadMore";

const FeedbackWithProfile = ({
  index,
  review,
  avatar,
  userName,
  updatedOn,
  expandedFeedback,
  handleToggleReadMore,
}) => {
  return (
    <div>
      <div className="flex gap-x-3">
        <img
          src={
            avatar ||
            handleDefaultProfile({
              userName: userName || "Seekho User",
            })
          }
          alt={userName}
          className="rounded-full w-[36px] h-[36px]"
        />
        <div className="flex flex-col">
          <div className="text-[12px] text-gray-400">
            <p className="break-words">
              {userName || "Seekho User"} •{" "}
              <span>
                {handleFormatDateTimeAgo({
                  date: updatedOn,
                })}
              </span>
            </p>
          </div>

          <CustomReadMore
            index={index}
            readMore={expandedFeedback}
            displayText={review}
            handleToggleReadMore={handleToggleReadMore}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedbackWithProfile;
