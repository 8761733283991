import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useHistory } from "react-router-dom";
import { topicsListingTab } from "../../utils/constants/Topics";
import {
  handleAllyProps,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
} from "../../utils/utils";

const TabsSection = ({ searchParamTabState }) => {
  const history = useHistory();

  // Tab Change
  const handleChangeTabs = (event, newValue) => {
    let state = topicsListingTab.filter((d) => d.index === newValue)?.[0]
      ?.value;
    handleAppendQueryToUrl({
      history,
      queryName: "state",
      queryValue: `${state}-${newValue}`,
    });
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    handleDeleteQueryFromUrl({ history, queryNames: ["s", "order"] });
  };

  return (
    <div>
      <Box>
        <Tabs
          value={searchParamTabState}
          onChange={handleChangeTabs}
          aria-label="basic tabs example"
          sx={{
            minHeight: 35,
            height: 35,
          }}
        >
          {topicsListingTab?.map((data, index) => (
            <Tab
              label={data?.id}
              {...handleAllyProps({ index })}
              key={index}
              sx={{
                minHeight: 35,
                height: 35,
              }}
            />
          ))}
        </Tabs>
      </Box>
    </div>
  );
};

export default TabsSection;
