import React from "react";

const ModeratorStatusChip = ({ reviewStatus, className }) => {
  return (
    <div
      className={` w-fit px-3 py-[3px] text-[12px] border capitalize rounded-full mx-auto ${
        className || ""
      } ${
        reviewStatus === "approved"
          ? "!bg-green-100 !text-green-500  !border-green-500"
          : reviewStatus === "rejected"
          ? "!bg-red-100 !text-red-500 !border-red-500"
          : "!bg-orange-100 !text-orange-500 !border-orange-500"
      } `}
    >
      {reviewStatus}
    </div>
  );
};

export default ModeratorStatusChip;
