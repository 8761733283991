import configActionTypes from "./config.types";

const INITIAL_STATE = {
  filters: {
    categories: [],
    unitStatus: [],
    seriesStatus: [],
    reviewStatus: [],
    contentManager: [],
    duration: [],
    postStatus: [],
    communities: [],
    isPremium: [],
    involvesActivity: [],
    isActivity: [],
    promotedSeries: [],
    question_status: [],
    isOriginal: [],
    experimentStatus: [],
    language_filter: [],
    language_mapping: [],
    is_dubbed_filter: [],
    is_news: [],
  },

  isFetching: false,

  errorMessage: undefined,
};

const ConfigReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case configActionTypes.FETCH_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: true,
      };
    case configActionTypes.FETCH_CONFIG_SUCCESS: {
      const filters = action.payload;
      return {
        ...state,
        filters: {
          categories: filters.categories,
          unitStatus: filters.unit_status,
          seriesStatus: filters.series_status,
          reviewStatus: filters.series_review_status,
          contentManager: filters.content_manager,
          duration: filters.duration,
          postStatus: filters.post_status,
          communities: filters.communities,
          isPremium: filters.is_premium,
          involvesActivity: filters.involves_activity,
          isActivity: filters.is_activity,
          promotedSeries: filters.promoted_series,
          questionStatus: filters.question_status,
          isOriginal: filters.original_series,
          experimentStatus: filters.experiment_status,
          start_on: filters.experiment_date_filter,
          end_on: filters.experiment_date_filter,
          notificationType: filters.notification_type,
          language_filter: filters.language_filter,
          language_mapping: filters?.language_mapping,
          is_dubbed_filter: filters.is_dubbed,
          is_news_filter: filters.is_news,
          seekho_team_roles: filters?.seekho_team_roles,
          external_team_roles: filters?.external_team_roles,
        },
      };
    }

    // eslint-disable-next-line no-duplicate-case
    case configActionTypes.FETCH_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default ConfigReducer;
