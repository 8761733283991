import { useSelector } from "react-redux";
import { TEAM_ROLES } from "../utils/constants/Team";

const useUserRoles = () => {
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const isSuperAdmin = currentUser?.roles?.includes(TEAM_ROLES.superAdmin);
  const isBlogger = currentUser?.roles?.includes(TEAM_ROLES.blogger);
  const isViewer = currentUser?.roles?.includes(TEAM_ROLES.viewer);
  const isEditor = currentUser?.roles?.includes(TEAM_ROLES.editor);
  const isModerator = currentUser?.roles?.includes(TEAM_ROLES.moderator);
  const isAdmin =
    currentUser?.roles?.includes(TEAM_ROLES.admin) || currentUser?.is_admin;
  const isCreator =
    currentUser?.roles?.includes(TEAM_ROLES.creator) ||
    currentUser?.is_quality_approved;
  const internalTeamAccess = isSuperAdmin || isAdmin || isEditor;
  const internalTeamAccessWithViewer = internalTeamAccess || isViewer;
  const hasAccessToMakeItLive = isSuperAdmin || isAdmin;
  const hasAccessToLanguage = internalTeamAccessWithViewer || isModerator;
  const isOnlyCreator =
    isCreator &&
    !isSuperAdmin &&
    !isAdmin &&
    !isEditor &&
    !isBlogger &&
    !isViewer &&
    !isModerator;

  return {
    isSuperAdmin,
    isBlogger,
    isViewer,
    isEditor,
    isAdmin,
    isCreator,
    internalTeamAccess,
    internalTeamAccessWithViewer,
    isOnlyCreator,
    isModerator,
    hasAccessToMakeItLive,
    hasAccessToLanguage,
  };
};

export default useUserRoles;
