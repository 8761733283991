import React, { useState } from "react";
import { handleDefaultProfile } from "../../utils/utils";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { apiGateway } from "../../utils/config";
import { useHistory } from "react-router-dom";
import {
  handleCreatorsTableHeading,
  handleCreatorStatus,
} from "../../utils/constants/Creators";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useUserRoles from "../../hooks/useUserRoles";

const ListingTable = ({
  data,
  setIncentiveDetails,
  setConfirmationDetails,
  setOpenIncentiveModal,
  setMessage,
  // handleApproveOrDisApprove,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { internalTeamAccess } = useUserRoles();
  const history = useHistory();
  const axiosPrivate = useAxiosPrivate();
  const hasAccessToActions = internalTeamAccess;

  // Fetches the incentive data of particular creator
  const handleGetIncentiveDetails = async ({ creatorId, creatorName }) => {
    try {
      setIsLoading(true);
      const url = `${apiGateway}/api/v1/creator/${creatorId}/payout/`;
      const response = await axiosPrivate.get(url);
      const { data } = response;
      if (data) {
        setIsLoading(false);
        setOpenIncentiveModal(true);
        setIncentiveDetails({
          creatorName,
          creatorId: data?.creator_id || creatorId,
          payoutType: data?.payout_type || "",
          amount: data?.fixed_amount || "",
          creatorType: data?.creator_type || "",
          contentManagerId: data?.content_manager?.id || "",
          tdsRate: data?.tds_rate || "",
          agreementDate: data?.start_date || "",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Function to map the drop down menu
  const handleMapDropDownMenu = ({ item }) => {
    const status = item?.status;
    const isActive = status === "active";
    // const isUnderReview = status === "under_review";
    const isInvited = status === "invited";
    const isInActive = status === "inactive";
    const creatorName = item?.name;
    const creatorId = item?.creator_id;
    const dropDownMenu = [
      // {
      //   title: "View Profile",
      //   display: isUnderReview || isActive,
      //   onClick: () => {},
      // },
      {
        title: isLoading ? "Loading...." : "Incentive Structure",
        display: true,
        onClick: () => {
          handleGetIncentiveDetails({ creatorId, creatorName });
        },
      },
      // {
      //   title: item?.is_quality_approved ? "Disapprove" : "Approve",
      //   display: isActive || isUnderReview,
      //   onClick: () => {
      //     handleApproveOrDisApprove({
      //       creatorId,
      //       isApprove: !item?.is_quality_approved,
      //     });
      //   },
      // },
      {
        title: item?.status == "active" ? "Make Inactive" : "Make Active",
        display: isActive || isInActive || isInvited,
        onClick: () => {
          setConfirmationDetails({
            open: true,
            type: item?.status === "active" ? "inactive" : "active",
            creatorId,
            creatorName,
          });
        },
      },
      {
        title: "Delete Profile",
        display: isInvited,
        onClick: () => {
          setConfirmationDetails({
            open: true,
            type: "delete",
            creatorId,
            creatorName,
          });
        },
      },
    ];
    return dropDownMenu;
  };

  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-gray-700 border-b bg-gray-100">
          <tr>
            {handleCreatorsTableHeading({
              hasAccessToActions,
            }).map(
              (item) =>
                item?.display && (
                  <th
                    scope="col"
                    className="px-6 py-3 !text-[12px] font-medium bg-gray-100 whitespace-nowrap z-[99]"
                    key={item?.title}
                  >
                    {item?.display && item?.title}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => {
            return (
              <tr
                className="!font-normal border-b last:border-none"
                key={item?.creator_id}
              >
                <td className="px-6 py-4 whitespace-nowrap flex items-center gap-x-3">
                  <img
                    src={
                      item?.avatar ||
                      handleDefaultProfile({ userName: item?.name })
                    }
                    alt={item.name}
                    className="!w-[40px] rounded-full"
                  />
                  <div
                    className="text-[14px] text-black cursor-pointer"
                    onClick={() =>
                      history.push(`/creators/${item?.creator_id}`)
                    }
                  >
                    {item?.name}
                    <div className="text-gray-500 text-[12px] mt-0">
                      {item?.phone}
                    </div>
                  </div>
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.show?.title || "-"}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.signedup_on}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.onboarded_by?.name || "-"}
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.content_manager?.name || "-"}
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.n_series}
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[13px]">
                  <span
                    className={`px-2 py-1 capitalize rounded-full ${handleCreatorStatus(
                      { status: item?.status }
                    )}`}
                  >
                    {item?.status === "under_review"
                      ? "Under Review"
                      : item?.status}
                  </span>
                </td>

                {hasAccessToActions && (
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Dropdown className="w-100">
                      <Dropdown.Toggle className="dropdown-action-common">
                        <FontAwesomeIcon
                          className="rejection-logs ml-1"
                          icon={faEllipsisV}
                          size="1x"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="action-menu">
                        {handleMapDropDownMenu({ item }).map((val) => {
                          return (
                            val.display && (
                              <Dropdown.Item
                                key={val?.title}
                                className="action-menu-item"
                                onClick={val?.onClick}
                              >
                                {val.title}
                              </Dropdown.Item>
                            )
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ListingTable;
