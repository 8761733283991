import React from "react";
import InputTitleWithRequired from "./InputTitleWithRequired";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { selectConfigFilters } from "../../redux/config/config.selector";

const CustomSelectLanguage = ({ language, setLanguage }) => {
  const languageOptions = useSelector(selectConfigFilters)?.language_filter;
  return (
    <div>
      <InputTitleWithRequired title="Language" />
      <Select
        displayEmpty
        className="w-full !text-[14px] mt-2"
        value={JSON.stringify(language)}
        onChange={(e) => {
          const { value } = e.target;
          const selectedCategory = JSON.parse(value);
          setLanguage({
            value: selectedCategory?.value,
            title: selectedCategory.title,
          });
        }}
      >
        <MenuItem
          disabled
          className="!text-[14px]"
          value={JSON.stringify({ value: "", title: "" })}
        >
          Select Language
        </MenuItem>
        {languageOptions?.map((item) => (
          <MenuItem
            value={JSON.stringify({
              value: item.value,
              title: item.label,
            })}
            key={item.value}
            className="!text-[14px]"
          >
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default CustomSelectLanguage;
