import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { apiGateway } from "../../utils/config";
import { LinearProgress, Pagination } from "@mui/material";
import { handleAppendQueryToUrl } from "../../utils/utils";
import { errorState } from "../../utils/constants";
import {
  allBlogsState,
  apiErrorState,
  blogsDetailsState,
  isLoadingState,
} from "../../utils/constants/Blogs";
import AddIcon from "@mui/icons-material/Add";
import CustomModal from "../Common/CustomModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AlertComponent from "../Alert-Messages/alert-component.component";
import CreateBlog from "./CreateBlog";
import BlogListingUI from "./BlogListingUI";
import NoDataFound from "../Common/NoDataFound";
import useUserRoles from "../../hooks/useUserRoles";

const BlogListing = () => {
  const [blogSlug, setBlogSlug] = useState("");
  const [openCreateBlog, setOpenCreateBlog] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPublishConfirmation, setOpenPublishConfirmation] = useState(false);
  const [openUnPublishConfirmation, setOpenUnPublishConfirmation] =
    useState(false);
  const [isError, setIsError] = useState(errorState);
  const [isLoading, setIsLoading] = useState(isLoadingState);
  const [isAPIError, setIsAPIError] = useState(apiErrorState);
  const [blogDetails, setBlogDetails] = useState(blogsDetailsState);
  const [allBlogs, setAllBlogs] = useState(allBlogsState);
  const { isBlogger, internalTeamAccess } = useUserRoles();
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const hasAccessToCreate = internalTeamAccess || isBlogger;

  const handleOpenCreateModal = () => {
    setOpenCreateBlog(true);
    setBlogDetails(blogsDetailsState);
  };

  // Check missing fields
  const handleGetMissingFields = () => {
    const requiredFields = [
      { key: "title", value: "Title" },
      { key: "description", value: "Description" },
    ];
    return requiredFields.filter((field) => !blogDetails?.[field.key]);
  };

  // Get All Blogs
  const handleGetAllBlogs = async ({ pageArg }) => {
    try {
      setIsLoading({ ...isLoading, getBlog: true });
      const url = `${apiGateway}/api/v1/blog/all/?request_source=cms&page=${
        pageArg || pageNumber
      }`;
      const response = await axiosPrivate.get(url);
      const blogs = response?.data?.blogs;
      const nPages = response?.data?.n_pages;
      if (response?.data) {
        setIsLoading({ ...isLoading, getBlog: false });
        setAllBlogs({ blogs, nPages });
        setIsAPIError({ ...isAPIError, noResponse: blogs?.length === 0 });
      }
    } catch (error) {
      setIsLoading({ ...isLoading, getBlog: false });
      setIsAPIError({ ...isAPIError, apiFail: true });
    }
  };

  // Create Blog
  const handleCreate = async () => {
    const missingFields = handleGetMissingFields();
    if (missingFields.length > 0) {
      setIsError({
        error: `${missingFields?.map((item) => {
          return ` ${item?.value}`;
        })} fields are required`,
        type: "error",
      });
    } else {
      try {
        setIsLoading({ ...isLoading, createBlog: true });
        const url = `${apiGateway}/api/v1/blog/create/`;
        const payload = new FormData();
        payload.append("title", blogDetails?.title);
        payload.append("description", blogDetails?.description);
        payload?.append("image", blogDetails?.displayImage);
        const response = await axiosPrivate.post(url, payload);
        if (response?.data) {
          const slug = response?.data?.blog?.slug;
          setIsLoading({ ...isLoading, createBlog: false });
          setIsError({
            type: "success",
            error: "Blog Created Successfully!!!",
          });
          history.push(`/blogs/edit-blog/${slug}`);
        }
      } catch (error) {
        setIsLoading({ ...isLoading, createBlog: false });
        setIsError({
          type: "error",
          error: error?.response?.data?.error_message || error?.message,
        });
      }
    }
  };

  // Function to Publish blog
  const handlePublishBlog = async () => {
    try {
      setIsLoading({ ...isLoading, publishBlog: true });
      const url = `${apiGateway}/api/v1/blog/${blogSlug}/publish/`;
      const response = await axiosPrivate.post(url);
      const data = response?.data;
      if (data) {
        handleGetAllBlogs({ pageArg: searchParamPage });
        setOpenPublishConfirmation(false);
        setIsLoading({ ...isLoading, publishBlog: false });
        setIsError({
          type: "success",
          error: "Blog Published Successfully!!!",
        });
      }
    } catch (error) {
      setIsLoading({ ...isLoading, publishBlog: false });
      setIsError({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  const handleUnPublishBlog = async () => {
    try {
      setIsLoading({ ...isLoading, unPublishBlog: true });
      const url = `${apiGateway}/api/v1/blog/${blogSlug}/unpublish/`;
      const response = await axiosPrivate.post(url);
      const data = response?.data;
      if (data) {
        handleGetAllBlogs({ pageArg: searchParamPage });
        setOpenUnPublishConfirmation(false);
        setIsLoading({ ...isLoading, unPublishBlog: true });
        setIsError({
          type: "success",
          error: "Blog Unpublished Successfully!!!",
        });
      }
    } catch (error) {
      setIsLoading({ ...isLoading, unPublishBlog: false });
      setIsError({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Delete blog
  const handleDeleteBlog = async ({ slug }) => {
    try {
      setIsLoading({ ...isLoading, deleteBlog: true });
      const url = `${apiGateway}/api/v1/blog/${slug}/delete/`;
      const response = await axiosPrivate.delete(url);
      if (response?.data) {
        handleGetAllBlogs({ pageArg: searchParamPage });
        setOpenDeleteModal(false);
        setIsLoading({ ...isLoading, deleteBlog: false });
        setIsError({ error: "Blog Delete Successfully!!!", type: "success" });
      }
    } catch (error) {
      setIsLoading({ ...isLoading, deleteBlog: false });
      setIsError({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  useEffect(() => {
    handleGetAllBlogs({ pageArg: searchParamPage });
  }, [searchParamPage]);

  return (
    <div>
      {isLoading.getBlog && <LinearProgress color="inherit" />}
      {hasAccessToCreate && (
        <button className="cta-button-create" onClick={handleOpenCreateModal}>
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Create Blog</span>
        </button>
      )}

      {isAPIError.apiFail || isAPIError?.noResponse ? (
        <div className="flex justify-center items-center h-screen">
          <NoDataFound
            className="w-[350px] mx-auto"
            displayText={`${
              isAPIError?.apiFail
                ? "Something went wrong"
                : "No Blogs to display, Start writing!!"
            }`}
          />
        </div>
      ) : (
        <div
          className="bg-white text-black text-[16px] pb-10 min-h-screen"
          id="HideScroll"
        >
          {/* // Blog Listing UI */}
          <BlogListingUI
            allBlogs={allBlogs?.blogs}
            setBlogSlug={setBlogSlug}
            setOpenDeleteModal={setOpenDeleteModal}
            setOpenPublishConfirmation={setOpenPublishConfirmation}
            setOpenUnPublishConfirmation={setOpenUnPublishConfirmation}
          />

          <Pagination
            count={allBlogs?.nPages}
            page={searchParamPage || pageNumber}
            onChange={(e, val) => {
              setPageNumber(val);
              handleAppendQueryToUrl({
                history,
                queryName: "page",
                queryValue: val,
              });
            }}
            shape="rounded"
            className="!w-fit !mx-auto mt-3"
          />
        </div>
      )}

      {/* Create Modal */}
      <CustomModal
        show={openCreateBlog}
        isLoading={isLoading.createBlog}
        title="Create Blog"
        key="Create-Blog"
        handleConfirmationBtnText="Create Blog"
        handleConfirmation={handleCreate}
        onHide={() => setOpenCreateBlog(false)}
      >
        <CreateBlog blogDetails={blogDetails} setBlogDetails={setBlogDetails} />
      </CustomModal>

      {/* Delete Blog Confirmation */}
      <CustomModal
        title="Delete Blog"
        key="Delete Blog"
        show={openDeleteModal}
        isLoading={isLoading?.deleteBlog}
        children={<div>Are you sure you want to delete the blog ?</div>}
        onHide={() => setOpenDeleteModal(false)}
        handleConfirmation={() => handleDeleteBlog({ slug: blogSlug })}
      />

      {/* Publish or UnPublish Blog Confirmation */}
      <CustomModal
        title={openPublishConfirmation ? "Publish Blog" : "Unpublish Blog"}
        handleConfirmationBtnText={
          openPublishConfirmation ? "Publish" : "Unpublish"
        }
        isLoading={isLoading.publishBlog || isLoading.unPublishBlog}
        show={openPublishConfirmation || openUnPublishConfirmation}
        onHide={() => {
          setOpenPublishConfirmation(false);
          setOpenUnPublishConfirmation(false);
        }}
        handleConfirmation={() => {
          if (openPublishConfirmation) handlePublishBlog();
          else handleUnPublishBlog();
        }}
      >
        Are you sure you want to{" "}
        {openPublishConfirmation ? "Publish" : "Unpublish"} the blog ?
      </CustomModal>

      {/* Alert */}
      <AlertComponent
        message={isError?.error}
        type={isError?.type}
        setAlertNotification={() => setIsError({ error: "", type: "failed" })}
      />
    </div>
  );
};

export default BlogListing;
