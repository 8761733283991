import React from "react";
import Topics from "../../components/Topics";

const TopicsPage = () => {
  return (
    <div className="mt-3">
      <Topics />
    </div>
  );
};

export default TopicsPage;
