import React from "react";
import { Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const QueryValueChip = ({ label, onDelete }) => {
  return (
    <div>
      <Chip
        size="large"
        className="my-2 p-1 !bg-[#b1aaed] text-white capitalize"
        label={label?.includes("-") ? label?.split("-")?.[0] : label}
        variant="filled"
        onDelete={onDelete}
        deleteIcon={<CancelIcon style={{ color: "white" }} />}
      />
    </div>
  );
};

export default QueryValueChip;
