import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  handleAppendQueryToUrl,
  handleClearSearchInput,
  handleCommonApplyFilters,
} from "../../utils/utils";
import { errorState } from "../../utils/constants";
import TabsSection from "./TabsSection";
import WaitingForApproval from "./WaitingForApproval";
import AlertComponent from "../Alert-Messages/alert-component.component";
import AllShowTopics from "./AllShowTopics";
import CustomSearch from "../Common/CustomSearch";
import FilterButton from "../Common/FilterButton";
import FilterDrawer from "../ShowsList/FilterDrawer";
import useAllQueryParams from "../../hooks/useAllQueryParams";
import FilterQueryChips from "../Common/FilterQueryChips";

const Topics = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [message, setMessage] = useState(errorState);
  const [selectedFilters, setSelectedFilters] = useState({
    category: [],
    manager: [],
  });
  const allQueryParams = useAllQueryParams({
    excludedParams: ["page", "state", "order"],
  });
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location?.search);
  const searchParamSortingKey = searchParams?.get("s") || "";
  const searchParamSortBy = searchParams?.get("order") || "";
  const searchParamCategoryFilter = searchParams?.getAll("category") || [];
  const searchParamManagerFilter = searchParams?.getAll("manager") || [];
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;

  // On hit enter
  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    }
  };

  // When clicked on Filter button
  const handleOnClickOfFilterButton = () => {
    setOpenFilterDrawer((prev) => !prev);
    setSelectedFilters({
      category: [...searchParamCategoryFilter],
      manager: [...searchParamManagerFilter],
    });
  };

  return (
    <div>
      <div className="flex gap-x-4">
        <CustomSearch
          searchQuery={searchQuery}
          placeHolder="Search show name or id"
          wrapperClass="w-[40%]"
          setSearchQuery={setSearchQuery}
          handleInputKey={handleInputKey}
          handleClearInput={() =>
            handleClearSearchInput({ history, setSearchQuery })
          }
        />
        <FilterButton onClick={handleOnClickOfFilterButton} />
      </div>

      <div>
        <FilterQueryChips
          allQueryParams={allQueryParams}
          setSearchQuery={setSearchQuery}
        />
      </div>

      <h6 className="!text-[20px] mt-2 font-medium">Topics</h6>
      <div className="mt-2">
        <TabsSection searchParamTabState={searchParamTabState} />
      </div>

      {searchParamTabState === 1 ? (
        <div>
          <WaitingForApproval
            searchParamSortBy={searchParamSortBy}
            searchParamSortingKey={searchParamSortingKey}
            searchParamCategoryFilter={searchParamCategoryFilter}
            searchParamManagerFilter={searchParamManagerFilter}
            setMessage={setMessage}
          />
        </div>
      ) : (
        <div>
          <AllShowTopics
            searchParamSortBy={searchParamSortBy}
            searchParamSortingKey={searchParamSortingKey}
            searchParamCategoryFilter={searchParamCategoryFilter}
            searchParamManagerFilter={searchParamManagerFilter}
            setMessage={setMessage}
          />
        </div>
      )}

      {/* Filter Drawer */}
      {openFilterDrawer && (
        <FilterDrawer
          openFilterDrawer={openFilterDrawer}
          selectedFilters={selectedFilters}
          showContentManagerFilter={true}
          setOpenFilterDrawer={setOpenFilterDrawer}
          setSelectedFilters={setSelectedFilters}
          handleApplyFilters={() => {
            handleCommonApplyFilters({
              history,
              selectedFilters,
              setOpenFilterDrawer,
            });
          }}
        />
      )}

      {/* Alert Toaster */}
      <AlertComponent
        message={message?.error}
        type={message?.type}
        setAlertNotification={() => setMessage({ error: "", type: "failed" })}
      />
    </div>
  );
};

export default Topics;
