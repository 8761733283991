import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { apiGateway } from "../../utils/config";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  handleAddCategoryToApiRoute,
  handleAddManagerToApiRoute,
  handlePageChange,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import TopicsListingTable from "../ShowsTopics/TopicsListingTable";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TopicsListSkeleton from "../Skeletons/TopicsListSkeleton";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";

const WaitingForApproval = ({
  searchParamSortBy,
  searchParamSortingKey,
  searchParamManagerFilter,
  searchParamCategoryFilter,
  setMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [topicsData, setTopicsData] = useState({ data: [], nPages: 1 });
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const globalLanguage = useSelector(
    (state) => state?.globalLanguage?.globalLanguage
  );

  // Function to get All the topic count of shows
  const handleGetTopics = async ({ pageArg = 1 }) => {
    try {
      setIsLoading(true);
      const categoryParams = handleAddCategoryToApiRoute({
        searchParamCategoryFilter,
      });
      const managerParams = handleAddManagerToApiRoute({
        searchParamManagerFilter,
      });
      let url = `${apiGateway}/api/v1/cms/series-titles/?page=${pageArg}&status=waiting_for_approval&page_size=20&language=${globalLanguage}${
        searchParamSortingKey
          ? `&s=${searchParamSortingKey}&order=${searchParamSortBy}`
          : ""
      }`;
      if (categoryParams) url += `&${categoryParams}`;
      if (managerParams) url += `&${managerParams}`;
      const response = await axiosPrivate.get(url);
      const { data } = response;
      if (data) {
        setIsLoading(false);
        setTopicsData({ data: data?.series_title_list, nPages: data?.n_pages });
      }
    } catch (error) {
      setIsLoading(false);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  useEffect(() => {
    handleGetTopics({ pageArg: searchParamPage });
  }, [
    searchParamPage,
    globalLanguage,
    searchParamSortBy,
    searchParamSortingKey,
    JSON.stringify(searchParamCategoryFilter),
    JSON.stringify(searchParamManagerFilter),
  ]);

  return (
    <div
      id="ThinGrayScrollBar"
      className="!relative !overflow-x-auto h-[calc(100vh-213px)] rounded-md !bg-white pb-2 border-t"
    >
      <div>
        {isLoading ? (
          <div>
            <TopicsListSkeleton columns={7} rows={15} isOnTopicsPage />
          </div>
        ) : (
          <div>
            {topicsData?.data?.length > 0 ? (
              <div>
                <TopicsListingTable
                  data={topicsData?.data}
                  setMessage={setMessage}
                  isOnTopicsPage
                  handleGetTopics={() => {
                    handleGetTopics({ pageArg: searchParamPage });
                  }}
                />
                {topicsData?.nPages > 1 && (
                  <div className="w-fit mx-auto">
                    <Pagination
                      count={topicsData?.nPages}
                      page={searchParamPage}
                      onChange={(_, value) =>
                        handlePageChange({ value, history })
                      }
                      shape="rounded"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="!bg-white h-[calc(100vh-200px)] flex items-center justify-center mt-2">
                <NoFeedbackSvj
                  className="w-[250px] mx-auto"
                  displayText={"No topics to display, Start adding!!"}
                  displayTextClass="mt-2 text-gray-400 text-[14px]"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WaitingForApproval;
