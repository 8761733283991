import React from "react";
import CustomFilterDrawer from "../Common/CustomFilterDrawer";
import { selectConfigFilters } from "../../redux/config/config.selector";
import { Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  handleAppendFiltersToUrl,
  handleCommonFilterChange,
} from "../../utils/utils";
import { moderationStatus } from "../../utils/constants/VideoListing";

const FilterDrawer = ({
  openFilterDrawer,
  selectedFilters,
  showModerationStatusFilter,
  showContentManagerFilter,
  setOpenFilterDrawer,
  handleApplyFilters,
  setSelectedFilters,
}) => {
  const history = useHistory();
  const configFilters = useSelector(selectConfigFilters);
  const { contentManager, categories } = configFilters;
  return (
    <CustomFilterDrawer
      open={openFilterDrawer}
      handleClose={() => setOpenFilterDrawer(false)}
      handleApplyFilters={handleApplyFilters}
      handleClearFilters={() => {
        handleAppendFiltersToUrl({ history, selectedFilters: {} });
        setOpenFilterDrawer(false);
      }}
    >
      <div>
        {showContentManagerFilter && (
          <div>
            <h5 className="filter-header px-2">Content Managers</h5>
            <Form.Group
              className="filters-list-series custom-drawer-list-series px-3"
              as={Row}
            >
              {contentManager?.map((item, index) => {
                const filterValue = `${item.name}-${item.id}`;
                return (
                  <Form.Check
                    key={item.id + `${index}`}
                    type="checkbox"
                    name="contentManagerFilter"
                    value={filterValue}
                    label={item.name}
                    checked={selectedFilters?.manager?.includes(filterValue)}
                    className="form-col col-md-6 form-checkbox-align"
                    onChange={(e) =>
                      handleCommonFilterChange({
                        event: e,
                        filterType: "manager",
                        setSelectedFilters: setSelectedFilters,
                      })
                    }
                  />
                );
              })}
            </Form.Group>
          </div>
        )}

        {showModerationStatusFilter && (
          <div>
            <h5 className="filter-header px-2">Moderation Status</h5>
            <Form.Group
              className="filters-list-series custom-drawer-list-series px-3"
              as={Row}
            >
              {moderationStatus?.map((item, index) => {
                const filterValue = `${item.value}-${item.id}`;
                return (
                  <Form.Check
                    key={item.id + `${index}`}
                    type="radio"
                    name="moderationStatusFilter"
                    value={filterValue}
                    label={item.title}
                    checked={selectedFilters?.moderation?.includes(filterValue)}
                    className="form-col col-md-6 form-checkbox-align"
                    onChange={(e) =>
                      handleCommonFilterChange({
                        event: e,
                        filterType: "moderation",
                        setSelectedFilters: setSelectedFilters,
                      })
                    }
                  />
                );
              })}
            </Form.Group>
          </div>
        )}

        <div className="pb-20">
          <h5 className="filter-header px-2">Categories</h5>
          <Form.Group
            className="filters-list-series custom-drawer-list-series px-3"
            as={Row}
          >
            {categories?.map((item, index) => {
              const filterValue = `${item.title}-${item.id}`;
              return (
                <Form.Check
                  key={item.id + `${index}`}
                  type="checkbox"
                  name="categoryFilter"
                  value={filterValue}
                  label={item.title}
                  checked={selectedFilters?.category?.includes(filterValue)}
                  className="form-col col-md-6 form-checkbox-align"
                  onChange={(e) =>
                    handleCommonFilterChange({
                      event: e,
                      filterType: "category",
                      setSelectedFilters: setSelectedFilters,
                    })
                  }
                />
              );
            })}
          </Form.Group>
        </div>
      </div>
    </CustomFilterDrawer>
  );
};

export default FilterDrawer;
