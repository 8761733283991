import React from "react";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { handleDefaultProfile, handleFormatDate } from "../../utils/utils";
import useUserRoles from "../../hooks/useUserRoles";

const BlogListingUI = ({
  allBlogs,
  setBlogSlug,
  setOpenDeleteModal,
  setOpenPublishConfirmation,
  setOpenUnPublishConfirmation,
}) => {
  const { isBlogger, internalTeamAccess } = useUserRoles();
  const hasAccessToActions = internalTeamAccess || isBlogger;

  const handleRouteToBlog = ({ blogSlug }) => {
    if (typeof window !== "undefined")
      window.location.href = `#/blogs/${blogSlug}`;
  };

  const handleRouteToEdit = ({ blogSlug }) => {
    if (typeof window !== "undefined")
      window.location.href = `#/blogs/edit-blog/${blogSlug}`;
  };

  return (
    <div>
      <div>
        {allBlogs?.map((item) => {
          return (
            <div
              className="border-b flex justify-between items-center px-4 py-3 gap-x-5 mt-2"
              key={item?.id || item?.slug}
            >
              {item?.image && (
                <div
                  className="hidden lg:block lg:h-[100px]"
                  onClick={() => handleRouteToBlog({ blogSlug: item?.slug })}
                >
                  <img
                    src={item?.image}
                    alt={item?.title}
                    className="w-[180px] h-[80px] object-contain cursor-pointer rounded-md mx-auto lg:h-[100px]"
                  />
                </div>
              )}

              <div className="w-full">
                <h1
                  className="font-bold capitalize cursor-pointer w-fit"
                  onClick={() => handleRouteToBlog({ blogSlug: item?.slug })}
                >
                  {item?.title}
                </h1>
                <div
                  className="text-[14px] line-clamp-1 mt-2 cursor-pointer"
                  onClick={() => handleRouteToBlog({ blogSlug: item?.slug })}
                >
                  {item?.description}
                </div>

                <div className="flex justify-between items-center mt-2">
                  <div className="flex items-center gap-x-2">
                    {item?.author && (
                      <div className="flex w-fit gap-x-2 items-center">
                        <img
                          src={handleDefaultProfile({ userName: item?.author })}
                          alt={item?.author}
                          className="w-[22px] h-[22px] rounded-full"
                        />
                        <div className="text-[12px]">
                          {item?.author || "Seekho User"}
                        </div>
                      </div>
                    )}

                    <p className="m-0 text-[12px] text-gray-600">
                      {handleFormatDate({
                        dateInString: item?.created_on,
                        showTime: false,
                      })}
                    </p>
                  </div>

                  <div className="flex items-center gap-x-4">
                    <div
                      className={`capitalize text-[10px] w-[60px] text-center text-white px-2 py-[2px] rounded-full ${
                        item?.status === "live"
                          ? "bg-green-600"
                          : item?.status === "deleted"
                          ? "bg-red-500"
                          : "bg-orange-500"
                      }`}
                    >
                      {item?.status}
                    </div>

                    {hasAccessToActions && (
                      <div>
                        {item?.status !== "deleted" && (
                          <Dropdown>
                            <Dropdown.Toggle className="dropdown-action-common">
                              <FontAwesomeIcon
                                className="rejection-logs ml-1 text-[14px] !cursor-pointer"
                                icon={faEllipsisH}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="action-menu">
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  handleRouteToEdit({ blogSlug: item?.slug });
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  setBlogSlug(item?.slug);
                                  setOpenDeleteModal(true);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="action-menu-item"
                                onClick={() => {
                                  setBlogSlug(item?.slug);
                                  if (item?.status === "live")
                                    setOpenUnPublishConfirmation(true);
                                  else setOpenPublishConfirmation(true);
                                }}
                              >
                                {item?.status === "live"
                                  ? "Unpublish"
                                  : "Publish"}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlogListingUI;
