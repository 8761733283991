/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { apiGateway } from "../../utils/config";
import { useSelector } from "react-redux";
import { selectConfigFilters } from "../../redux/config/config.selector";
import {
  CATEGORY_CMS_ALL,
  errorState,
  GET_CREATOR_LIST,
  SHOW_CREATE_V1,
  SHOW_UPDATE_V1,
} from "../../utils/constants";
import "./create-show.styles.css";
import { Container, Form } from "react-bootstrap";
import { Button, MenuItem, Select, Switch } from "@mui/material";
import { useHistory } from "react-router-dom";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FileUploader from "../Common/FileUploader";
import InputTitleWithRequired from "../Common/InputTitleWithRequired";
import CustomSelectLanguage from "../Common/CustomSelectLanguage";

function CreateShow({ toggleCreate, show_data, setShow }) {
  const [creator, setCreator] = useState(null);
  const [showSlug, setShowSlug] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [description, setDescription] = useState("");
  const [editBannerImage, setEditBannerImage] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isExperimental, setIsExperimental] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorState);
  const [category, setCategory] = useState({ id: "", title: "" });
  const [creators, setCreators] = useState([]);
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const axiosPrivate = useAxiosPrivate();
  const languageMapping = useSelector(selectConfigFilters)?.language_mapping;
  const globalLanguage = useSelector(
    (val) => val?.globalLanguage?.globalLanguage
  );
  const languageSetter = {
    value: globalLanguage,
    title: languageMapping?.[globalLanguage],
  };
  // pre filling the language with respect to the global language
  const [language, setLanguage] = useState(languageSetter);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let missingFields = [];
    if (!title) missingFields.push("Title");
    if (!creator?.id) missingFields.push("Creator");
    if (!category?.id) missingFields.push("Category");
    if (!isEdit && !thumbnail) missingFields.push("Thumbnail");
    if (!isEdit && !bannerImage) missingFields.push("Banner Image");
    if (!description) missingFields.push("Description");
    if (!language?.value) missingFields.push("Language");
    if (missingFields.length > 0) {
      setErrorMsg({
        type: "failed",
        error: `Please complete the required fields: ${missingFields.join(
          ", "
        )}`,
      });
      return;
    }
    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("category_id", category?.id);
    data.append("creator_id", creator?.id);
    data.append("image", thumbnail);
    data.append("banner_image", bannerImage);
    data.append("is_experimental", isExperimental);
    data.append("language", language?.value);

    let url = `${apiGateway}${SHOW_CREATE_V1}`;
    if (isEdit) {
      data.append("slug", showSlug);
      url = `${apiGateway}${SHOW_UPDATE_V1}`;
    }
    try {
      setShowLoader(true);
      const response = await axiosPrivate.post(url, data);
      if (response?.data) {
        if (isEdit) {
          setShow(response?.data?.show_data);
          history.push(`/shows/${response?.data?.show_data?.slug}`);
        }
        toggleCreate(e, true);
        setShowLoader(false);
        setErrorMsg({
          type: "success",
          error: isEdit
            ? "Show Updated Successfully"
            : "Show Created Successfully",
        });
      }
    } catch (error) {
      setShowLoader(false);
      setErrorMsg({
        type: "failed",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  const handleSearchCreator = async (e) => {
    const searchName = e.target.value;
    setSearchInput(searchName);
    if (searchName.length >= 3) {
      const url = `${apiGateway}${GET_CREATOR_LIST}?page=${1}&q=${searchName}`;
      axiosPrivate
        .get(url)
        .then(({ data, status }) => {
          if (Object.keys(data).length !== 0 && status === 200)
            setCreators(data.users);
        })
        .catch(({ response }) => {
          setErrorMsg({
            type: "failed",
            error: response?.data
              ? response?.data?.error_message
              : "Failed to fetch creator",
          });
        });
    } else {
      setCreators([]);
      setCreator(null);
    }
  };

  const handleSelectCreator = (creator) => {
    setCreator(creator);
    setSearchInput(creator.name);
  };

  const handleBannerImageChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (file) {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        if (aspectRatio === 800 / 480) {
          setBannerImage(file);
        } else {
          setErrorMsg({
            type: "failed",
            error: "Banner must have an aspect ratio of 800:480",
          });
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  // const validateShowTitles = async () => {
  //   const url = `${apiGateway}${VALIDATE_SHOW_TITLE}`;

  //   if (title == ''){
  //     setErrorMsg({
  //       type: "failed",
  //       error: "Please enter show title.",
  //     });
  //     return;
  //   }
  //   const data = new FormData();
  //   data.append("show_title", title);
  //   axiosPrivate.post(url, data)
  //   .then(async ({data, status}) => {
  //     if(status === 200){
  //       setTitleVerified(true);
  //     }else{
  //       setTitleVerified(false);
  //     }
  //   })
  //   .catch(({response}) => {
  //     setErrorMsg({
  //       type: "failed",
  //       error: response?.data ? response?.data?.error_message : "Something went wrong",
  //     });
  //     setTitleVerified(false);
  //   });
  // };

  useEffect(() => {
    if (Object.keys(show_data || {})?.length > 0) {
      setTitle(show_data?.title);
      setShowSlug(show_data?.slug);
      setDescription(show_data?.description ?? "");
      setCreator(show_data?.creator);
      handleSelectCreator(show_data?.creator);
      setImage(show_data?.image);
      setEditBannerImage(show_data?.banner_image);
      setIsEdit(true);
      setIsExperimental(show_data?.is_experimental);
      setLanguage({
        value: show_data?.language,
        title: languageMapping?.[show_data?.language] || "",
      });
      setCategory({
        id: show_data?.category?.id,
        title: show_data?.category?.title,
      });
    }
  }, [show_data]);

  useEffect(() => {
    let url = `${apiGateway}${CATEGORY_CMS_ALL}?page=1&default_only=true&page_size=500`;
    axiosPrivate
      .get(url)
      .then(({ data, status }) => {
        if (status === 200) {
          setCategories([...data.categories]);
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Category failed to fetch",
        });
      });
  }, []);

  return (
    <div>
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() => setErrorMsg({ error: "", type: "failed" })}
      />
      <Container className="create-series">
        <div>
          <Form onSubmit={handleSubmit}>
            {/* Title */}
            <Form.Label>
              <InputTitleWithRequired title="Title" />
            </Form.Label>
            <div className="series-title-input mb-2">
              <Form.Control
                type="text"
                placeholder="Enter the title of shows"
                className="text-[14px]"
                required
                name="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              {/* <Button variant="outlined" disabled={title == '' || titleVerified} className="cta-update-series-cancel ml-2" onClick={validateShowTitles}>
                Validate
              </Button> */}
            </div>
            <div>
              {/* Creator */}
              <Form.Group>
                <Form.Label>
                  <InputTitleWithRequired title="Creator" />
                </Form.Label>
                <div className="search-select min-w-full">
                  <div className="dropdown">
                    <input
                      autoComplete="off"
                      value={searchInput}
                      name="searchInput"
                      placeholder="Enter Creator Name"
                      className="text-[14px] px-2"
                      onChange={handleSearchCreator}
                    />
                    <div className="dropdown-content">
                      {creators?.map((creator, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => handleSelectCreator(creator)}
                          >
                            {creator.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Form.Group>

              {/* Custom Component for Selecting language */}
              <CustomSelectLanguage
                language={language}
                setLanguage={setLanguage}
              />

              {/* Category */}
              <div className="mt-3">
                <InputTitleWithRequired title="Category" />
                <Select
                  displayEmpty
                  className="w-full !text-[14px] mt-2"
                  value={JSON.stringify(category)}
                  onChange={(e) => {
                    const { value } = e.target;
                    const selectedCategory = JSON.parse(value);
                    setCategory({
                      id: selectedCategory.id,
                      title: selectedCategory.title,
                    });
                  }}
                >
                  <MenuItem
                    disabled
                    value={JSON.stringify({ id: "", title: "" })}
                  >
                    Select Category
                  </MenuItem>
                  {categories?.map((item) => (
                    <MenuItem
                      value={JSON.stringify({ id: item.id, title: item.title })}
                      key={item.id}
                      className="!text-[14px]"
                    >
                      {item?.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              {/* Show Icon */}
              <Form.Group
                controlId="exampleForm.ControlInput1"
                className="mt-3"
              >
                <Form.Label>
                  <InputTitleWithRequired title="Icon" />
                </Form.Label>
                <br />
                <div>
                  <FileUploader
                    accept=".png, .jpeg, .jpg"
                    displayText="Select Icon"
                    key={thumbnail?.name || ""}
                    displayIcon={<FontAwesomeIcon icon={faImage} />}
                    onChange={(e) => {
                      setThumbnail(e.target.files[0]);
                    }}
                  />

                  {thumbnail ? (
                    <div className="w-[100px] mx-auto text-center">
                      <img
                        src={URL.createObjectURL(thumbnail)}
                        alt="thumbnailImage"
                      />
                      <FontAwesomeIcon
                        className="mt-1 cursor-pointer"
                        icon={faTrashAlt}
                        onClick={() => setThumbnail(null)}
                      />
                    </div>
                  ) : (
                    image && (
                      <div className="w-[100px] mx-auto">
                        <img src={image} alt="thumbnailImage" />
                      </div>
                    )
                  )}
                </div>
              </Form.Group>

              {/* Show Banner */}
              <Form.Group>
                <Form.Label>
                  <InputTitleWithRequired title="Banner Image" />
                </Form.Label>
                <br />
                <div>
                  <FileUploader
                    accept=".png, .jpeg, .jpg"
                    displayText="Select Banner Image (Make sure banner dimensions are 800x480)"
                    displayIcon={<FontAwesomeIcon icon={faImage} />}
                    key={bannerImage?.name || ""}
                    onChange={handleBannerImageChange}
                  />

                  {bannerImage ? (
                    <div className="w-[200px] mx-auto text-center">
                      <img src={URL.createObjectURL(bannerImage)} />
                      <FontAwesomeIcon
                        className="mt-1 cursor-pointer"
                        icon={faTrashAlt}
                        onClick={() => setBannerImage(null)}
                      />
                    </div>
                  ) : (
                    editBannerImage && (
                      <div className="w-[200px] mx-auto">
                        <img src={editBannerImage} alt="bannerImage" />
                      </div>
                    )
                  )}
                </div>
              </Form.Group>

              {/* Show Description */}
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>
                  <InputTitleWithRequired title="Description" />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter the description of shows"
                  className="text-[14px]"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>

              {/* Experimental */}
              <Form.Group>
                <Form.Label>
                  <InputTitleWithRequired
                    title="Experimental"
                    isRequired={false}
                  />
                </Form.Label>
                <Switch
                  checked={isExperimental}
                  onChange={() => {
                    setIsExperimental(!isExperimental);
                  }}
                />
              </Form.Group>
            </div>
            <div className="center">
              <Button
                type="submit"
                disabled={showLoader}
                variant="outline-primary"
                active
                className="cta-update-series"
              >
                {!showLoader ? (isEdit ? "Update" : "Create") : "Saving...."}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default CreateShow;
