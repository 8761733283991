import React, { useState } from "react";
import { handleDefaultProfile } from "../../../utils/utils";
import { logout, setCurrentUser } from "../../../redux/user/user.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBars,
  faChevronDown,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { handleDisplayRole } from "../../../utils/constants/Team";
import { ChevronLeftRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleSetGlobalLanguage } from "../../../redux/language/action";
import { selectConfigFilters } from "../../../redux/config/config.selector";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomModal from "../CustomModal";
import SeekhoWhiteLogo from "../../SideNavigation/Icons/seekho-white.png";
import LogoutIcon from "@mui/icons-material/Logout";
import MobileSideDrawer from "./MobileSideDrawer";
import useZustandStore from "../../../zustandStore/useZustandStore";
import CustomBreadcrumbs from "../CustomBreadCrumb";
import useUserRoles from "../../../hooks/useUserRoles";

const Navbar = ({ currentUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const { hasAccessToLanguage } = useUserRoles();
  const { showSideNavigationBar, setShowSideNavigationBar } = useZustandStore();
  const languageOptions = useSelector(selectConfigFilters).language_filter;
  const languageMapping = useSelector(selectConfigFilters).language_mapping;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const open = anchorEl;
  const openLang = langAnchorEl;
  const globalLanguage = useSelector(
    (state) => state?.globalLanguage?.globalLanguage
  );

  const handleClickOnMyProfile = () => {
    setAnchorEl(null);
    window.location.href = "#/profile";
  };

  const handleBack = () => {
    history.goBack(); // This works like router.back() in Next.js
  };

  return (
    <div className="relative">
      <div className="!px-3 bg-black flex justify-between !py-4 items-center lg:!px-2 lg:!py-1 z-[999]">
        {/* Logo, sidebar toggle and breadcrumbs */}
        <div className="flex items-center gap-x-2 lg:gap-x-16">
          <div className="flex items-center gap-x-6">
            <Tooltip
              title={
                showSideNavigationBar ? "Collapse Sidebar" : "Expand Sidebar"
              }
              className="!hidden lg:!block"
            >
              <IconButton>
                <FontAwesomeIcon
                  icon={faBars}
                  className="text-white cursor-pointer text-[20px] hidden lg:block"
                  onClick={() =>
                    setShowSideNavigationBar(!showSideNavigationBar)
                  }
                />
              </IconButton>
            </Tooltip>

            <img src={SeekhoWhiteLogo} alt="seekho" className="w-[100px]" />
          </div>

          <div className="flex gap-x-2">
            <div className="hidden lg:flex lg:gap-x-3 lg:items-center">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="!text-white text-[14px] mt-1 cursor-pointer"
                onClick={handleBack}
              />
              <CustomBreadcrumbs />
            </div>
          </div>
        </div>

        {/* Language and Profile */}
        <div className="flex gap-x-2 items-center">
          {hasAccessToLanguage && (
            <div className="lg:flex lg:gap-x-2 lg:items-center">
              <Tooltip title="Select Language">
                <Button
                  id="basic-button"
                  aria-controls={openLang ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openLang ? true : undefined}
                  onClick={(e) => setLangAnchorEl(e.currentTarget)}
                  className="border !border-blue-400 !bg-blue-600 text-white !font-bold px-2 !py-[2px] !text-[12px] !rounded-md !normal-case flex gap-x-2"
                  size="small"
                >
                  {languageMapping?.[globalLanguage]}{" "}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="text-[11px]"
                  />
                </Button>
              </Tooltip>

              <Menu
                id="basic-menu-lang"
                anchorEl={langAnchorEl}
                open={openLang}
                onClose={() => setLangAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {languageOptions?.map((item) => {
                  return (
                    <MenuItem
                      className="w-[100px] text-center"
                      key={item.value}
                      onClick={() => {
                        dispatch(handleSetGlobalLanguage(item.value));
                        setLangAnchorEl(null);
                        const searchParams = new URLSearchParams(
                          location.search
                        );
                        searchParams.delete("page"); // Removing the page parameter from the URL
                        history.replace({
                          pathname: location.pathname,
                          search: `?${searchParams.toString()}`,
                          hash: location.hash,
                        });
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          )}

          {/* Profile button with DropDown Menu */}
          <div className="hidden lg:flex lg:gap-x-2 lg:items-center">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <img
                src={
                  currentUser?.avatar ||
                  handleDefaultProfile({
                    userName: currentUser?.name,
                  })
                }
                alt={currentUser?.name}
                className="w-[32px] h-[32px] rounded-full object-contain hover:animate-pulse"
              />
              <ChevronLeftRounded className="-rotate-90 text-white" />
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="italic w-[180px] hover:!bg-transparent !-mt-2">
                <span className="text-baseBlueColor font-semibold">Hey</span>
              </MenuItem>
              <MenuItem className="!py-0 hover:!bg-transparent">
                <div className="text-[14px] font-bold line-clamp-1 border-b border-gray-300 w-full">
                  {currentUser?.name}
                </div>
              </MenuItem>
              <MenuItem className="mt-2 py-0 bg-white">
                <div
                  className="flex gap-1 max-w-[180px] overflow-x-auto"
                  id="HideScroll"
                >
                  {currentUser?.roles?.map((item, index) => (
                    <span
                      key={index}
                      className="text-[10px] border px-2 rounded-full bg-gray-200 text-gray-700"
                    >
                      {handleDisplayRole({ role: item })}
                    </span>
                  ))}
                </div>
              </MenuItem>
              <MenuItem
                onClick={handleClickOnMyProfile}
                className="border-t mt-2"
              >
                <hr className="border" />
                <div className="flex gap-x-3 items-center ">
                  <div className="w-[20px]">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <p>My Profile</p>
                </div>
              </MenuItem>
              <MenuItem
                className="mt-2"
                onClick={() => {
                  setAnchorEl(null);
                  setOpenLogoutModal(true);
                }}
              >
                <div className="flex gap-x-3 items-center ">
                  <LogoutIcon />
                  <p>Logout</p>
                </div>
              </MenuItem>
            </Menu>
          </div>

          <div className="flex items-center gap-x-1 lg:!hidden">
            <FontAwesomeIcon
              icon={faBars}
              className="text-white text-[20px] "
              onClick={() => setOpenSideDrawer(true)}
            />
          </div>
        </div>

        {/* Logout confirmation */}
        <CustomModal
          title="Logout Seekho CMS"
          handleConfirmationBtnText="Logout"
          show={openLogoutModal}
          isLoading={isLoading}
          onHide={() => setOpenLogoutModal(false)}
          handleConfirmation={() => {
            setIsLoading(true);
            logout(setCurrentUser, setOpenLogoutModal, setIsLoading, "");
          }}
        >
          Are you sure you want to logout?
        </CustomModal>
      </div>

      {/* Mobile Sidebar */}
      <div className="lg:hidden">
        <MobileSideDrawer
          openSideDrawer={openSideDrawer}
          currentUser={currentUser}
          setOpenSideDrawer={setOpenSideDrawer}
          setOpenLogoutModal={setOpenLogoutModal}
        />
      </div>
    </div>
  );
};

export default Navbar;
