import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../Common/CustomInput";
import InputTitleWithRequired from "../Common/InputTitleWithRequired";
import CustomSelectLanguage from "../Common/CustomSelectLanguage";

const AddCreator = ({ creatorDetails, setCreatorDetails }) => {
  const commonInputFields = [
    {
      id: "creator-name-input",
      type: "text",
      placeholder: "Enter Name",
      value: creatorDetails?.name,
      label: "Creator Name",
      isRequired: true,
      onChange: (e) => {
        setCreatorDetails({ ...creatorDetails, name: e.target.value });
      },
    },
    {
      id: "creator-number-input",
      type: "number",
      placeholder: "Creator's 10 digit Mobile Number ",
      value: creatorDetails?.mobileNumber,
      label: "Creator Mobile Number",
      isRequired: true,
      isNumberValid: creatorDetails?.mobileNumber?.length === 10,
      maxLength: "10",
      onChange: (e) => {
        if (e.target.value.length <= 10)
          setCreatorDetails({
            ...creatorDetails,
            mobileNumber: e.target.value,
          });
      },
    },
  ];

  return (
    <div>
      {commonInputFields.map((item) => {
        return (
          <div className="relative first:!mt-0 mt-4 w-full" key={item?.id}>
            <InputTitleWithRequired title={item?.label} />
            <div className="relative">
              <CustomInput
                type={item?.type}
                id={item?.id}
                placeholder={item?.placeholder}
                value={item?.value}
                onChange={item?.onChange}
                maxLength={item?.maxLength}
                autoComplete="off"
                className="w-full py-3 placeholder:text-gray-500"
              />
              {item?.isNumberValid && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="absolute right-2 top-[55%] transform -translate-y-1/2 text-green-500 text-[15px]"
                />
              )}
            </div>
          </div>
        );
      })}

      <div className="mt-3">
        <CustomSelectLanguage
          language={creatorDetails?.language}
          setLanguage={(lang) => {
            setCreatorDetails({ ...creatorDetails, language: lang });
          }}
        />
      </div>
    </div>
  );
};

export default AddCreator;
