/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { apiGateway } from "../../utils/config";
import {
  errorState,
  GET_SERIES_LIST,
  GET_SERIES_V1,
  SERIES_SCHEDULE_V1,
} from "../../utils/constants";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  allSeriesDefaultState,
  handleSeriesStateTabs,
  openScheduledModalState,
} from "../../utils/constants/VideoListing";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Box, LinearProgress, Pagination, Tab, Tabs } from "@mui/material";
import {
  handleAppendFiltersToUrl,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
  handleFormatDate,
  schedularTimeList,
  handleAllyProps,
  handleCommonApplyFilters,
  handleAddCategoryToApiRoute,
} from "../../utils/utils";
import { TEAM_ROLES } from "../../utils/constants/Team";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SeriesListTable from "./SeriesListTable";
import CustomSearch from "../Common/CustomSearch";
import useZustandStore from "../../zustandStore/useZustandStore";
import AlertComponent from "../Alert-Messages/alert-component.component";
import CreateSeries from "../Create-Series/create-series.component";
import moment from "moment";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import ShowPills from "../Common/ShowPills";
import ShowPillsSkeleton from "../Skeletons/ShowPillsSkeleton";
import FilterButton from "../Common/FilterButton";
import CustomModal from "../Common/CustomModal";
import useUserRoles from "../../hooks/useUserRoles";
import FilterDrawer from "../ShowsList/FilterDrawer";
import useAllQueryParams from "../../hooks/useAllQueryParams";
import FilterQueryChips from "../Common/FilterQueryChips";

const VideoListing = ({
  isShowUploadCta = true,
  isOnShowDetailsPage,
  isOnCreatorDetailsPage,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTag, setSearchQueryTag] = useState("");
  const [openFilters, setOpenFilters] = useState(false);
  const [errorForScheduling, setErrorForScheduling] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    category: [],
    moderation: "",
  });
  const [scheduledData, setScheduledData] = useState({});
  const [errorMsg, setErrorMsg] = useState(errorState);
  const [allSeries, setAllSeries] = useState(allSeriesDefaultState);
  const [isLoading, setIsLoading] = useState({ series: true, shows: true });
  const [openScheduleModal, setOpenScheduleModal] = useState(
    openScheduledModalState
  );
  const { internalTeamAccess, isCreator, hasAccessToLanguage } = useUserRoles();
  const { openCreateSeriesModal, setOpenCreateSeriesModal } = useZustandStore();
  const allQueryParams = useAllQueryParams({
    excludedParams: ["page", "show", "state"],
  });
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const location = useLocation();
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const searchParams = new URLSearchParams(location?.search);
  const globalLanguage = useSelector(
    (state) => state?.globalLanguage?.globalLanguage
  );
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;
  const searchParamSearchQuery = searchParams?.get("query") || "";
  const searchParamCategoryFilter = searchParams?.getAll("category") || [];
  const searchParamModerationFilter = searchParams?.get("moderation") || "";
  const searchParamShowSlug = searchParams?.get("show") || "";
  const hasAccessToCreate = internalTeamAccess || isCreator;
  // because the initial value from zustand store was undefined so had to use currentUser
  const hasAccessToAllTabs =
    currentUser?.roles?.includes(TEAM_ROLES.admin) ||
    currentUser?.roles?.includes(TEAM_ROLES.superAdmin) ||
    currentUser?.roles?.includes(TEAM_ROLES.editor) ||
    currentUser?.roles?.includes(TEAM_ROLES.viewer) ||
    currentUser?.roles?.includes(TEAM_ROLES.moderator);

  const handleGetSeries = async ({
    currentActiveTab,
    pageArg,
    query,
    passedId,
  }) => {
    try {
      setIsLoading({
        series: true,
        shows: allSeries?.shows?.[0] ? false : true,
      });
      let state = handleSeriesStateTabs({
        hasAccessToAllTabs,
      }).filter((d) => d.index === currentActiveTab)?.[0]?.value;

      const categoryParams = handleAddCategoryToApiRoute({
        searchParamCategoryFilter,
      });

      let url = `${apiGateway}${GET_SERIES_LIST}?page=${
        pageArg || pageNumber
      }&q=${query}${state !== "all" ? `&status=${state}` : ""}${
        isOnShowDetailsPage?.showSlug
          ? `&show_slug=${isOnShowDetailsPage?.showSlug}`
          : ""
      }${
        isOnCreatorDetailsPage?.creatorId
          ? `&creator_ids=${isOnCreatorDetailsPage?.creatorId}`
          : ""
      }${searchParamShowSlug ? `&show_slug=${searchParamShowSlug}` : ""}${
        hasAccessToLanguage ? `&language=${globalLanguage}` : ""
      }${
        searchParamModerationFilter
          ? `&review_status=${searchParamModerationFilter?.split("-")[0]}`
          : ""
      }`;

      // Add category if they exist
      if (categoryParams) {
        url += `&${categoryParams}`;
      }

      const response = await axiosPrivate.get(url);
      const data = response?.data;
      if (data) {
        setIsLoading({ series: false, shows: false });
        setAllSeries({
          nPages: data?.n_pages,
          series: data?.series,
          shows: data?.shows,
        });
      }
    } catch (error) {
      setAllSeries({ nPages: 0, series: [] });
      setIsLoading({ series: false, shows: false });
      setErrorMsg({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Function on tab change
  const handleChangeTabs = (event, newValue) => {
    let state = handleSeriesStateTabs({ hasAccessToAllTabs }).filter(
      (d) => d.index === newValue
    )?.[0]?.value;
    handleAppendQueryToUrl({
      history,
      queryName: "state",
      queryValue: `${state}-${newValue}`,
    });
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
  };

  // function on Page change
  const handlePageChange = (e, value) => {
    setPageNumber(value);
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: value });
  };

  // function on hit enter
  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
      setSearchQueryTag(searchQuery);
    }
  };

  // when clicked on particular show
  const handleOnShowClick = ({ showSlug }) => {
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    handleAppendQueryToUrl({
      history,
      queryName: "show",
      queryValue: showSlug,
    });
    handleDeleteQueryFromUrl({
      history,
      queryNames: ["page", "s", "order"],
    });
  };

  // when clicked on All Show CTA Pill
  const handleClickOnAllShowCTA = () => {
    handleDeleteQueryFromUrl({
      history,
      queryNames: ["page", "s", "order", "show"],
    });
  };

  // function fetches fresh data after any action made
  const handleFetchSeriesAfterCreated = () => {
    handleGetSeries({
      currentActiveTab: searchParamTabState,
      pageArg: searchParamPage,
      query: searchQuery || searchParamSearchQuery,
    });
  };

  const getDateTimeFormat = (date) => {
    const newDate = moment(date).format("DD MM YYYY");
    return newDate?.split(" ").join(" - ");
  };

  // Old function code to update the scheduled date
  const handleUpdateScheduleDat = async (data, type, edit) => {
    let url = `${apiGateway}${SERIES_SCHEDULE_V1}`;
    let dynamicFormDate;
    if (type === "custom") {
      dynamicFormDate = new Date(data).toLocaleString();
    } else {
      dynamicFormDate = new Date(
        `${data?.value?.split("/")[0]}-${data?.value?.split("/")[1]}-${
          data?.value?.split("/")[2]
        }`
      );
    }
    const dynamicDate =
      type === "custom"
        ? `${dynamicFormDate?.split(",")[0]?.split("/")[2]}-${
            dynamicFormDate?.split(",")[0]?.split("/")[1]
          }-${dynamicFormDate?.split(",")[0]?.split("/")[0]}T${
            dynamicFormDate?.split(", ")[1]
          }+05:30`
        : `${dynamicFormDate.getFullYear()}-${
            dynamicFormDate.getMonth() + 1
          }-${dynamicFormDate.getDate()}T18:00:00+05:30`;

    let formData = new FormData();
    if (edit?.length > 0) {
      formData.append("schedule_on", dynamicDate);
      formData.append("series_slug", openScheduleModal?.slug);
    } else {
      formData.append("schedule_on", dynamicDate);
      formData.append(
        "series_slugs",
        JSON.stringify([openScheduleModal?.slug])
      );
    }
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        const isScheduleSuccess =
          (status === 200 &&
            data?.failure_slugs?.length === 0 &&
            data?.success_count === 1) ||
          data?.series_schedule?.status;
        if (isScheduleSuccess) {
          handleGetSeries({
            currentActiveTab: searchParamTabState,
            query: searchParamSearchQuery,
            page: 1,
          });
          setErrorMsg({ error: "Scheduled successfully", type: "success" });
          setOpenScheduleModal(openScheduledModalState);
        } else {
          setErrorForScheduling(data?.failure_slugs);
        }
        if (data?.message?.length > 0) {
          setErrorMsg({ error: data?.message, type: "failed" });
          setOpenScheduleModal(openScheduledModalState);
        }
        setPageNumber(1);
      })
      .catch(({ response }) => {
        setErrorMsg({
          error: response?.data
            ? response?.data?.message
            : "Unable to create scheduling",
          type: "failed",
        });
        setOpenScheduleModal(openScheduledModalState);
      });
  };

  // Function to remove the scheduled date
  const handleRemoveScheduled = async ({ seriesSlug }) => {
    let url = `${apiGateway}${GET_SERIES_V1}${seriesSlug}/schedule/delete/`;
    try {
      const response = await axiosPrivate.delete(url);
      const { data } = response;
      if (data) {
        setErrorMsg({ error: data?.message, type: "success" });
        handleGetSeries({
          currentActiveTab: searchParamTabState,
          query: searchParamSearchQuery,
          page: 1,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Unable to remove scheduling";
      setErrorMsg({
        error: errorMessage,
        type: "failed",
      });
    }
  };

  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  // useEffect handling get API on every dependencies change
  useEffect(() => {
    handleGetSeries({
      currentActiveTab: searchParamTabState,
      pageArg: searchParamPage,
      query: searchQuery || searchParamSearchQuery,
    });
  }, [
    searchParamTabState,
    searchParamPage,
    searchParamSearchQuery,
    globalLanguage,
    JSON.stringify(searchParamCategoryFilter),
    isOnShowDetailsPage?.showSlug,
    searchParamShowSlug,
    searchParamModerationFilter,
  ]);

  return (
    <div className="relative">
      <div>
        <div className="flex gap-x-3 !w-full flex-col lg:flex-row">
          <div className="flex w-full gap-x-4">
            {/* Custom Search & Filter CTA */}
            <div className="w-[70%] lg:!w-[55%]">
              <CustomSearch
                placeHolder="Search video title or video id"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleInputKey={handleInputKey}
                handleClearInput={() => {
                  setSearchQuery("");
                  setSearchQueryTag("");
                  handleDeleteQueryFromUrl({
                    history,
                    queryNames: ["query", "page"],
                  });
                }}
              />
            </div>
            <FilterButton
              onClick={() => {
                setOpenFilters(true);
                setSelectedFilters({
                  category: [...searchParamCategoryFilter],
                });
              }}
            />
          </div>

          {isShowUploadCta && (
            <div className="flex items-center mt-3 lg:!mt-0">
              {hasAccessToCreate && (
                <Button
                  size="small"
                  variant="contained"
                  className="text-[13px] !bg-[#534f8f] !text-white h-fit w-[140px] py-[10px]"
                  onClick={() => setOpenCreateSeriesModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-1" /> Upload
                  Video
                </Button>
              )}
            </div>
          )}
        </div>

        <div className="flex gap-x-2 items-center mt-2 overflow-x-auto max-w-full">
          <h6 className="text-[20px] font-medium flex-shrink-0">Videos</h6>
          <FilterQueryChips
            allQueryParams={allQueryParams}
            setSearchQuery={setSearchQuery}
          />
        </div>

        {isOnShowDetailsPage?.value || isOnCreatorDetailsPage?.value ? null : (
          <div className="mt-2">
            {isLoading?.shows ? (
              <ShowPillsSkeleton />
            ) : (
              <div>
                {allSeries?.shows && (
                  <ShowPills
                    shows={allSeries?.shows}
                    searchParamShowSlug={searchParamShowSlug}
                    handleOnShowClick={handleOnShowClick}
                    handleClickOnAllShowCTA={handleClickOnAllShowCTA}
                  />
                )}
              </div>
            )}
          </div>
        )}

        {/* State Tabs */}
        <div className="mt-3" style={{ overflowX: "auto" }}>
          <Box>
            <Tabs
              value={searchParamTabState}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto" // Enables scrolling when tabs overflow
              allowScrollButtonsMobile // Enables scroll buttons for mobile view
              sx={{
                minHeight: 35,
                height: 35,
              }}
            >
              {handleSeriesStateTabs({
                hasAccessToAllTabs,
              })?.map((data, i) => (
                <Tab
                  label={data?.id}
                  {...handleAllyProps(i)}
                  key={i}
                  sx={{
                    minHeight: 35,
                    height: 35,
                  }}
                  className="!text-[13px]"
                />
              ))}
            </Tabs>
          </Box>
        </div>
      </div>

      {/* Listing table with pagination and UI handle when no series */}
      <div>
        {isLoading.series && <LinearProgress color="inherit" />}
        <div
          id="ThinGrayScrollBar"
          className={`!relative !overflow-x-auto ${
            Object.keys(allQueryParams)?.length
              ? "h-[calc(100vh-295px)]"
              : "h-[calc(100vh-275px)]"
          } rounded-md !bg-white pb-2 !border-t`}
        >
          {!isLoading.series && allSeries?.series?.length === 0 ? (
            <div className="h-full flex justify-center items-center">
              <NoFeedbackSvj
                displayText="No videos to display"
                displayTextClass="mt-2 text-gray-400 text-[14px] text-center"
                className="w-[250px] mx-auto flex justify-center items-center"
              />
            </div>
          ) : (
            <div>
              <SeriesListTable
                allSeries={allSeries?.series}
                setOpenScheduleModal={setOpenScheduleModal}
                setScheduledData={setScheduledData}
                handleRemoveScheduled={handleRemoveScheduled}
                handleGetSeries={() =>
                  handleGetSeries({
                    currentActiveTab: searchParamTabState,
                    query: searchParamSearchQuery,
                    pageArg: searchParamPage,
                  })
                }
              />
              {allSeries?.nPages > 1 && (
                <Pagination
                  count={allSeries?.nPages}
                  page={searchParamPage || pageNumber}
                  onChange={(e, val) => handlePageChange(e, val)}
                  shape="rounded"
                  className="w-fit mx-auto pb-3"
                />
              )}
            </div>
          )}
        </div>
      </div>

      {/* Filter Side Drawer same component from shows */}
      <FilterDrawer
        openFilterDrawer={openFilters}
        selectedFilters={selectedFilters}
        showModerationStatusFilter={true}
        setOpenFilterDrawer={setOpenFilters}
        setSelectedFilters={setSelectedFilters}
        handleApplyFilters={() =>
          handleCommonApplyFilters({
            history,
            selectedFilters,
            setOpenFilterDrawer: setOpenFilters,
          })
        }
        handleClearAllFilters={() => {
          handleAppendFiltersToUrl({ history, selectedFilters: {} });
        }}
      />

      {/* CREATE Video MODAL */}
      <CustomModal
        title="Upload Video"
        className="lg:!w-[33%]"
        show={openCreateSeriesModal}
        isButtonsRequired={false}
        onHide={() => setOpenCreateSeriesModal(false)}
      >
        <CreateSeries
          toggleCreate={() => setOpenCreateSeriesModal(false)}
          handleFetchSeriesAfterCreated={handleFetchSeriesAfterCreated}
        />
      </CustomModal>

      {/* Old code of Update scheduled date Modal */}
      <Modal show={openScheduleModal?.openModal} centered>
        <Modal.Header
          closeButton
          onHide={() => {
            setOpenScheduleModal(openScheduledModalState);
            setErrorForScheduling([]);
            setScheduledData({});
          }}
        >
          <div className="modal-schedule-title">
            <div className="modal-schedule-header">Schedule Publish</div>
            <div className="modal-schedule-subtext">India Standard Time</div>
          </div>
        </Modal.Header>
        {errorForScheduling?.length > 0 ? (
          errorForScheduling?.map((data, i) => (
            <div key={i}>
              <ul className="modal-schedule-content-ul">
                <li className="text-[15px]">
                  <span className="text-red-500">⚠️ Issue </span>
                  {i + 1}. <strong>{data.series_slug}</strong> –{" "}
                  {data?.error_message}
                </li>
              </ul>
            </div>
          ))
        ) : (
          <div className="modal-schedule-content">
            <ul className="modal-schedule-content-ul">
              {schedularTimeList(0)?.map((dateinfo, i) => {
                return (
                  <li
                    key={i}
                    className="modal-schedule-content-li"
                    onClick={() =>
                      handleUpdateScheduleDat(
                        dateinfo,
                        "",
                        scheduledData?.schedule_on
                      )
                    }
                  >
                    <div className="modal-date-title">{dateinfo?.title}</div>
                    <div>
                      <span className="modal-date-date">
                        {getDateTimeFormat(dateinfo?.value)}
                      </span>
                      <span className="modal-date-time">{dateinfo?.time}</span>
                    </div>
                  </li>
                );
              })}
              {scheduledData?.schedule_on && (
                <li className={`modal-schedule-content-li selected-date`}>
                  <div className="modal-date-title">Scheduled Date</div>
                  <div>
                    <span className="modal-date-date">
                      {handleFormatDate({
                        dateInString: scheduledData?.schedule_on,
                      })}
                    </span>
                  </div>
                </li>
              )}
            </ul>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="Select time and Date"
                  disablePast
                  ampm={false}
                  onAccept={(newValue) =>
                    newValue &&
                    handleUpdateScheduleDat(
                      newValue?.$d,
                      "custom",
                      scheduledData?.schedule_on
                    )
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}
      </Modal>

      {/* Alert Component */}
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() =>
          setErrorMsg({ error: "", type: errorMsg?.type })
        }
      />
    </div>
  );
};

export default VideoListing;
