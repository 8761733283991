/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { apiGateway } from "../../utils/config";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import {
  handleAddCategoryToApiRoute,
  handleAddManagerToApiRoute,
  handlePageChange,
} from "../../utils/utils";
import ShowListTable from "../ShowsList/ShowListTable";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../Loader";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";

const AllShowTopics = ({
  searchParamSortBy,
  searchParamSortingKey,
  searchParamCategoryFilter,
  searchParamManagerFilter,
  setMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ data: [], nPages: 1 });
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const location = useLocation();
  const globalLanguage = useSelector(
    (state) => state?.globalLanguage?.globalLanguage
  );
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const searchParamSearchQuery = searchParams.get("query") || "";

  // Function to get All the show topics
  const handleGetAllShowsTopics = async ({ pageArg, query }) => {
    try {
      setIsLoading(true);
      const categoryParams = handleAddCategoryToApiRoute({
        searchParamCategoryFilter,
      });

      const managerParams = handleAddManagerToApiRoute({
        searchParamManagerFilter,
      });

      let url = `${apiGateway}/api/v1/cms/shows/?page=${pageArg}&language=${globalLanguage}&state=live${
        query ? `&q=${query}` : ""
      }${
        searchParamSortingKey
          ? `&s=${searchParamSortingKey}&order=${searchParamSortBy}`
          : ""
      }`;
      // Add category if they exist
      if (categoryParams) url += `&${categoryParams}`;
      if (managerParams) url += `&${managerParams}`;

      const response = await axiosPrivate.get(url);
      const { data } = response;
      if (data) {
        setIsLoading(false);
        setData({ data: data?.show_list, nPages: data?.n_pages });
      }
    } catch (error) {
      setIsLoading(false);
      setMessage({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  useEffect(() => {
    handleGetAllShowsTopics({
      pageArg: searchParamPage,
      query: searchParamSearchQuery,
    });
  }, [
    globalLanguage,
    searchParamPage,
    searchParamSearchQuery,
    searchParamSortBy,
    searchParamSortingKey,
    JSON.stringify(searchParamCategoryFilter),
    JSON.stringify(searchParamManagerFilter),
  ]);

  return (
    <div>
      <div
        id="ThinGrayScrollBar"
        className="!relative !overflow-x-auto h-[calc(100vh-208px)] rounded-md !bg-white pb-2 border-t"
      >
        {isLoading ? (
          <div className="h-[calc(100vh-165px)] flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div>
            {data?.data?.length > 0 ? (
              <div>
                <ShowListTable
                  isOnTopicsPage
                  data={data?.data}
                  hasAccessToActions={false}
                  hasAccessToCreatorTableField
                />

                {data?.nPages > 1 && (
                  <div className="w-fit mx-auto mt-2">
                    <Pagination
                      count={data?.nPages}
                      page={searchParamPage}
                      onChange={(_, value) =>
                        handlePageChange({ value, history })
                      }
                      shape="rounded"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="!bg-white h-[calc(100vh-200px)] flex items-center justify-center mt-2">
                <NoFeedbackSvj
                  className="w-[250px] mx-auto"
                  displayText={"No show topics to display, Start adding!!"}
                  displayTextClass="mt-2 text-gray-400 text-[14px]"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AllShowTopics;
