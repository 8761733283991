import React from "react";
import { Chip } from "@mui/material";
import { handleShowTableHeadline } from "../../utils/constants/Shows";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import {
  handleSeriesStatusColor,
  seriesStatus,
} from "../../utils/constants/VideoListing";
import placeholder from "../../assests/images/placeholder1.png";
import CustomAnchor from "../Common/CustomAnchor";
import SeriesIdCopy from "../Common/SeriesIdCopy";

// Same table as been used in show list page and topics page with conditional rendering
const ShowListTable = ({
  data,
  isEditor,
  hasAccessToActions,
  isOnTopicsPage = false,
  handleDeleteShow,
  handleToggleShowLiveOrDraft,
  hasAccessToCreatorTableField,
}) => {
  const history = useHistory();

  const handleRouteToShow = ({ showSlug }) => {
    history.push(`/shows/${showSlug}`);
  };

  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-gray-700 !bg-gray-100 !border-b">
          <tr>
            {handleShowTableHeadline({
              history,
              hasAccessToActions,
              hasAccessToCreatorTableField,
              isOnTopicsPage,
            })?.map((item) => {
              return (
                item?.display && (
                  <th
                    scope="col"
                    className={`px-3 py-3 whitespace-nowrap !text-[12px] text-gray-700 !bg-gray-100 z-[99] !font-medium `}
                    key={item.id}
                  >
                    <div className="flex gap-x-2">
                      {item?.title}
                      {item?.sortIcon && (
                        <button onClick={item?.onClick}>
                          {item?.sortIcon}
                        </button>
                      )}
                    </div>
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => {
            const isLive = item?.status === seriesStatus.LIVE;
            const isDraft = item?.status === seriesStatus.DRAFT;
            const isDeleted = item?.status === seriesStatus.DELETED;
            return (
              <tr
                key={item?.id}
                className="last:!border-none border-b text-black !font-normal text-[12px]"
              >
                {!isOnTopicsPage && (
                  <td className="px-3 py-3 min-w-[200px] lg:w-[200px]">
                    <div
                      className="flex gap-x-1 items-center cursor-pointer"
                      onClick={() =>
                        handleRouteToShow({ showSlug: item?.slug })
                      }
                    >
                      <img
                        src={item?.image || placeholder}
                        alt={item?.title}
                        className="w-[50px] h-[50px] rounded"
                      />

                      <img
                        src={item?.banner_image || placeholder}
                        alt={item?.title}
                        className="w-[84px] h-[50px] rounded"
                      />
                    </div>
                  </td>
                )}

                <td className="px-3 py-3 text-[14px] whitespace-nowrap cursor-pointer tracking-wide lg:whitespace-normal lg:!max-w-[180px]">
                  <CustomAnchor
                    displayText={item?.title}
                    href={`/#/${isOnTopicsPage ? "shows-topics" : "shows"}/${
                      item?.slug
                    }`}
                  />
                  <div>
                    <SeriesIdCopy
                      seriesId={item?.id}
                      className="text-[10px] py-0"
                    />
                  </div>
                </td>

                {hasAccessToCreatorTableField && (
                  <td className="px-3 py-3 text-[14px] text-gray-500 whitespace-nowrap ">
                    <CustomAnchor
                      displayText={item?.creator?.name}
                      href={`/#/creators/${item?.creator?.id}`}
                    />
                  </td>
                )}

                <td className="px-3 py-3 text-[14px] text-gray-500 whitespace-nowrap ">
                  {item?.content_manager?.name || "-"}
                </td>

                <td className="px-3 py-3 text-[14px] text-gray-500 whitespace-nowrap ">
                  {item?.n_series}
                </td>

                <td className="px-3 py-3 text-[14px] text-gray-500 whitespace-nowrap ">
                  {item?.category?.title}
                </td>

                <td className={` px-3 py-3 text-[14px] capitalize`}>
                  {isOnTopicsPage ? (
                    <span className="text-green-500">
                      {item?.n_titles_approved}
                    </span>
                  ) : (
                    <Chip
                      label={item?.status}
                      className={` ${handleSeriesStatusColor({
                        status: item?.status,
                      })} w-fit px-3 `}
                    />
                  )}
                </td>

                {isOnTopicsPage && (
                  <td className="px-3 py-3 text-[14px] text-blue-500 whitespace-nowrap">
                    {item?.n_titles_waiting_for_approval}
                  </td>
                )}

                {hasAccessToActions ? (
                  <td className="px-3 py-2 text-[14px] capitalize">
                    <Dropdown>
                      <Dropdown.Toggle className="dropdown-action">
                        <FontAwesomeIcon
                          className="rejection-logs ml-1"
                          icon={faEllipsisV}
                          size="xs"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {!isDraft && (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleToggleShowLiveOrDraft({ item })
                            }
                          >
                            Move To Draft
                          </Dropdown.Item>
                        )}

                        {isLive || isDeleted || isEditor ? (
                          ""
                        ) : (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() =>
                              handleToggleShowLiveOrDraft({ item })
                            }
                          >
                            Make Live
                          </Dropdown.Item>
                        )}

                        {!isDeleted && (
                          <Dropdown.Item
                            className="action-menu-item"
                            onClick={() => handleDeleteShow({ item })}
                          >
                            Delete Show
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ShowListTable;
