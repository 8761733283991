import axios from "axios";
import { apiGateway } from "../utils/config";

export const axiosPublic = axios.create({
  baseURL: apiGateway,
});

export const axiosPrivate = axios.create({
  baseURL: apiGateway,
  headers: {
    withCredentials: true,
  },
});

// Use interceptors to add the packageName header globally
const addPackageNameInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers["package-name"] = "com.seekho.cms";
      return config;
    },
    (error) => Promise.reject(error)
  );
};

// Add interceptors to both axios instances
addPackageNameInterceptor(axiosPublic);
addPackageNameInterceptor(axiosPrivate);
